import {
    makeAutoObservable
} from 'mobx';
import moment from 'moment';
import { RootStore } from '.';

export default class GeoStore {

    private rootStore: RootStore;

    cities: any = []

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async fetchCities() {
        const { apiStore } = this.rootStore
        return new Promise(async (resolve, reject) => {
            try {
                let cities = this.getStoredCities()
                // Check if cities are already in cache
                let cacheDate: any = localStorage.getItem('@dct_cities_loaded_at')
                if (cities != null && cacheDate) {
                    cacheDate = moment(JSON.parse(cacheDate))
                    // City cache lasts 1 hour
                    if (moment().diff(cacheDate, 'minutes') < 60) {
                        this.setCities(cities)
                        resolve(cities)
                        return
                    }
                }

                // Load cities from API
                let resp: any = await apiStore.post("address/cities", null);
                this.cities = resp
                this.setCities(resp)
                resolve(resp)
            } catch (error) {
                this.removeStoredCities()
                console.log(error)
                reject(error)
            }
        })
    }

    async setCities(cities: any) {
        this.cities = cities
        if (cities) {
            localStorage.setItem('@dct_cities', JSON.stringify(cities))
            localStorage.setItem('@dct_cities_loaded_at', JSON.stringify(new Date()))
        } else {
            localStorage.removeItem('@dct_cities')
        }
    }

    getStoredCities() {
        const jsonValue = localStorage.getItem('@dct_cities')
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    }

    removeStoredCities() {
        this.cities = null
        localStorage.removeItem('@dct_cities')
    }

    getCityObjectById(id: string){
        let findById = this.cities.filter((city: any) => city.id == id)
        if(findById.length > 0){
            return findById[0]
        }else{
            return null
        }
    }
}
