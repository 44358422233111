import BGImg from '../../assets/images/backgrounds/main-slider-1-1.jpg'
import BGImg1 from '../../assets/images/backgrounds/main-slider-1-2.jpg'
import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AnimatedNumber from "animated-number-react"
import { jarallax } from "jarallax";
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useStores } from '../../stores'
import { BASE_URL } from '../../utils/settings'

const Home = () => {

    const { apiStore } = useStores()

    const [ytShow, setytShow] = useState(false)
    const [tabMenu, tabActive] = useState<any>(null)


    return (
        <>

            <section className="main-slider">
                <Swiper
                    className="swiper-container thm-swiper__slider"
                    modules={[Navigation, Autoplay]}
                    slidesPerView={1}
                    navigation={{
                        nextEl: "#main-slider__swiper-button-next",
                        prevEl: "#main-slider__swiper-button-prev"
                    }}
                //autoplay={{ delay: 5000 }}
                >
                    <div className="swiper-wrapper">
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="image-layer" style={{ backgroundImage: `url(${BGImg})` }}>
                                </div>

                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <h2 className='mb-0 text-center'>Un lavage de voiture sans eau livré chez vous</h2>
                                            <p className="my-4 text-white text-center">Rejoignez la communauté DROPLESS – Inscrivez-vous maintenant pour réserver votre lavage</p>
                                            <div className='badge-inline-row text-center'>
                                                <span className="badge">RDV en ligne</span>
                                                <span className="badge">Déplacement à domicile</span>
                                                <span className="badge">Sans eau</span>
                                            </div>
                                            <div className="d-flex">
                                                <Link to="/prendre-rendez-vous" className="thm-btn m-auto">Prendre rendez-vous <i className="far fa-arrow-alt-circle-right"></i></Link >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </div>

                    {/* <div className="main-slider__nav">
                        <div className="swiper-button-prev" id="main-slider__swiper-button-next"><i className="crsine-left-arrow"></i>
                        </div>
                        <div className="swiper-button-next" id="main-slider__swiper-button-prev"><i className="crsine-right-arrow"></i>
                        </div>
                    </div> */}
                </Swiper>
            </section>

            <section className="about-two">
                <div className="container">
                    <div className="row flex-lg-row-reverse">
                        <div className="col-lg-6">
                            <div className="about-two__image wow fadeInRight" data-wow-duration="1500ms">
                                <img src="assets/images/resources/about-2-1.jpg" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-two__content">
                                <div className="section-title">
                                    <span className="section-title__tagline">UN LAVE-AUTO SANS EAU À TAHITI</span>
                                    <h2 className="section-title__title">Revisitez la manière de laver votre voiture</h2>
                                </div>
                                {/* <ul className="about-two__list list-unstyled">
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Un côté super pratique
                                    </li>
                                </ul> */}
                                <div className="about-two__summery">
                                    Plus besoin de se déplacer pour laver votre véhicule. Asseyez-vous et détendez-vous, nos agents Dropless vous livrent votre lave-auto où que vous soyez stationnés entre Arue et Punaauia.  Gérez, planifiez et payez facilement un lave-auto en quelques secondes. Enregistrez-vous, réservez votre lavage, et laissez-nous embellir votre journée.
                                </div>
                                <br />
                                <hr className="section-separator" />
                                <div className="about-two__icon-box">
                                    <i className=" crsine-car-service-3 about-two__icon"></i>
                                    <div className="about-two__icon-content">
                                        Nous lavons + de 30 véhicules par mois, particuliers et professionnels
                                    </div>
                                </div>
                                <div className="about-two__button-box">
                                    <Link to="/prendre-rendez-vous" className="thm-btn">
                                        Je réserve
                                        <i className="far fa-arrow-alt-circle-right"></i>
                                    </Link >
                                    <div className="about-two__phone">
                                        <i className="crsine-phone-call"></i>
                                        <div className="about-two__phone-content">
                                            <span>Appelez-nous</span>
                                            <Link to="tel:+68989502319">89 50 23 19</Link>
                                            <Link to="tel:+68989502319">89 40 23 20</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-two py-0">
                <div className="container">
                    <div className="row flex-lg">
                        <div className="col-12 col-md-8">
                            <div className="about-two__content">
                                <div className="section-title">
                                    <span className="section-title__tagline">SERVICE NUMÉRIQUE</span>
                                    <h2 className="section-title__title">Un côté innovant</h2>
                                </div>
                                <div className="about-two__summery">
                                    Dropless est un service entièrement numérique et autonome. Depuis votre mobile ou ordinateur, réservez, gérez et indiquez votre stationnement sur la carte google map.<br /><br />Le lavage de votre véhicule ne nécessitera aucune goutte d’eau. Nos solutions <b>nano</b> nettoyage permettent, d’éliminer la saleté efficacement en toute sécurité sur toutes les surfaces.
                                </div>
                                <br />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                            <img className='m-auto' style={{ maxHeight: 400 }} src='assets/images/home/phone_book_map.jpg' />
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-two pt-0">
                <div className="container">
                    <div className="row flex-lg">
                        <div className="col-12 col-md-4 d-flex">
                            <img className='m-auto' style={{ maxWidth: '100%' }} src='assets/images/home/planet_water.png' />
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="about-two__content">
                                <div className="section-title">
                                    <span className="section-title__tagline">ÉCO-RESPONSABLE</span>
                                    <h2 className="section-title__title">Un côté éco-durable</h2>
                                </div>
                                <div className="about-two__summery">
                                    Respectueuse de l’environnement, nos solutions éco nano sont 100% Biodégradables et nous aident à économiser plus de 150 litres d’eau à chaque lavage.<br /><br />
                                    En effet, nous n’avons aucunement besoin d’eau et d’électricité.<br />
                                    Grace à ces produits écologiques, vous participerez activement à la préservation de la plus importante de nos ressources, l’eau.<br />
                                    Agissons pour notre FENUA et soyons fiers d’avoir contribué à la protection de notre planète
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="feature-lists">
                <div className="feature-lists__boxed jarallax" ref={activeRef} data-jarallax>
                    <img src="assets/images/backgrounds/feature-bg-1-1.jpg" alt="" className="jarallax-img" />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="section-title">
                                    <span className="section-title__tagline">NOS SERVICES</span>
                                    <h2 className="section-title__title">Inclus dans nos prestations</h2>
                                </div>
                                <Link to="/prendre-rendez-vous" className="thm-btn">Prendre rendez-vous <i className="far fa-arrow-alt-circle-right"></i></Link >

                            </div>
                            <div className="col-lg-8">
                                <div className="feature-lists__wrap">

                                    <ul className="list-unstyled feature-lists__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Rétroviseurs
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Pare choc
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Bas de caisse
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Portières
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled feature-lists__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Tableau de bord
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Lorem ipsum
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Lorem ipsum
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Lorem ipsum
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled feature-lists__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Lorem ipsum
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Lorem ipsum
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="call-to-action">
                <div className="container">
                    <h3 className="call-to-action__title">Prenez rendez-vous simplement en ligne</h3>

                    <div className="call-to-action__btn-wrap">
                        <Link to="/prendre-rendez-vous" className="thm-btn white">
                            Je réserve
                            <i className="far fa-arrow-alt-circle-right"></i>
                        </Link >
                    </div>
                </div>
            </section>
            <section className="feature-one">
                <div className="video-one wow fadeInLeft" data-wow-duration="1500ms">
                    <img src="assets/images/resources/video-1-1.jpg" alt="" />
                    <div className="video-one__content">
                        {/* <span onClick={() => setytShow(true)} className="video-popup" ><i className="fa fa-play"></i></span> */}
                        <p className="video-one__text">Unique au Fenua</p>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-xl-end">
                        <div className="col-lg-7">
                            <div className="feature-one__content">
                                <div className="section-title">
                                    <span className="section-title__tagline">AVANTAGES</span>
                                    <h2 className="section-title__title">Pourquoi choisir <br />
                                        nos services ?</h2>
                                </div>
                                <div className="feature-one__summery">
                                    Choisissez nos services pour un nettoyage automobile pratique et respectueux de l'environnement. Nous vous faisons gagner du temps en venant à vous, sans avoir besoin d'eau courante.
                                </div>
                                <ul className="list-unstyled feature-one__list">
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Pas d'eau nécessaire
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Écologique
                                    </li>
                                </ul>
                                <ul className="list-unstyled feature-one__list">
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Pratique et rapide
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Résultats impeccables
                                    </li>
                                </ul>
                                <ul className="list-unstyled feature-one__list">
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Tout type de véhicule
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Mobile et pratique
                                    </li>
                                </ul>

                                <div className="feature-one__counter">
                                    <i className="crsine-car-service feature-one__counter-icon"></i>

                                    <div className="feature-one__counter-content">
                                        <h3 className="feature-one__counter-title odometer" data-count="867000"><AnimatedNumber value={3650} duration={1000} formatValue={(v: any) => Math.round(v)} /></h3>

                                        <p className="feature-one__counter-text">Clients satisfaits</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="pricing-one">
                <div className="container">
                    <div className="section-title text-center">
                        <span className="section-title__tagline">NOS TARIFS</span>
                        <h2 className="section-title__title">Consultez nos forfaits</h2>
                    </div>

                    <Link to="/prendre-rendez-vous" className="thm-btn">Prendre rendez-vous <i className="far fa-arrow-alt-circle-right"></i></Link >

                </div>
            </section> */}
            <br /><br /><br />

            <section className="feature-two">
                <div className="container">
                    <div className="row flex-xl-row-reverse">
                        <div className="col-xl-6">
                            <div className="feature-two__image">
                                <img src="assets/images/resources/feature-1-1.jpg" alt="" />
                                <div className="feature-two__image-text wow flipInY" data-wow-duration="1500ms">
                                    <i className="feature-two__image-line-left"></i>
                                    <i className="feature-two__image-line-right"></i>
                                    <i className="feature-two__image-line-top"></i>
                                    <i className="feature-two__image-line-bottom"></i>
                                    <span>7</span>
                                    Années
                                    d'expérience
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="feature-two__content">
                                <div className="section-title">
                                    <span className="section-title__tagline">LAVAGE RÉINVENTÉ</span>
                                    <h2 className="section-title__title">Un processus de nettoyage repensé et soucieux de l'environnement.</h2>
                                </div>

                                <ul className="list-unstyled feature-two__list">
                                    <li className="feature-two__list-item">
                                        <i className="feature-two__list-icon crsine-car"></i>
                                        <div className="feature-two__list-content">
                                            <h3 className="feature-two__list-title">
                                                Qualité supérieure
                                            </h3>
                                            <p className="feature-two__list-text">
                                                Alternative novatrice et écologique aux méthodes traditionnelles de lavage de voitures
                                            </p>
                                        </div>
                                    </li>
                                    <li className="feature-two__list-item">
                                        <i className="feature-two__list-icon crsine-tire"></i>
                                        <div className="feature-two__list-content">
                                            <h3 className="feature-two__list-title">
                                                Propre jusqu'aux jantes
                                            </h3>
                                            <p className="feature-two__list-text">
                                                Tout aussi efficace, voire mieux qu'un lavage avec eau !
                                            </p>
                                        </div>
                                    </li>
                                    <li className="feature-two__list-item">
                                        <i className="feature-two__list-icon crsine-mat"></i>
                                        <div className="feature-two__list-content">
                                            <h3 className="feature-two__list-title">
                                                Efficace à l'intérieur
                                            </h3>
                                            <p className="feature-two__list-text">
                                                Nous proposons également des forfaits qui prennent en charge l'intérieur du véhicule
                                            </p>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="gallery-two__home-title">
                <div className="container">
                    Galerie
                </div>
            </div>

            <div className="gallery-two gallery-two__home">
                <Swiper
                    className="swiper-container thm-swiper__slider"
                    modules={[Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{ delay: 5000 }}
                    breakpoints={{
                        "376": {
                            "spaceBetween": 30,
                            "slidesPerView": 2
                        },
                        "576": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "768": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "992": {
                            "spaceBetween": 30,
                            "slidesPerView": 4
                        },
                        "1200": {
                            "spaceBetween": 30,
                            "slidesPerView": 5
                        }
                    }}
                >
                    <div className="swiper-wrapper">
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="gallery-one__item">
                                    <img src={BASE_URL + 'assets/images/gallery/1.jpeg'} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="gallery-one__item">
                                    <img src={BASE_URL + 'assets/images/gallery/2.jpeg'} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="gallery-one__item">
                                    <img src={BASE_URL + 'assets/images/gallery/3.jpeg'} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="gallery-one__item">
                                    <img src={BASE_URL + 'assets/images/gallery/4.jpeg'} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="gallery-one__item">
                                    <img src={BASE_URL + 'assets/images/gallery/5.jpeg'} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="gallery-one__item">
                                    <img src={BASE_URL + 'assets/images/gallery/6.jpeg'} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="gallery-one__item">
                                    <img src={BASE_URL + 'assets/images/gallery/7.jpeg'} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="gallery-one__item">
                                    <img src={BASE_URL + 'assets/images/gallery/8.jpeg'} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </div>
                </Swiper>
            </div>

            <section className="blog-home">
                <div className="container">
                    <div className="blog-home__top">
                        <div className="section-title">
                            <span className="section-title__tagline">VOUS ÊTES UN PROFESSIONNEL ?</span>
                            <h2 className="section-title__title">Confiez-nous votre flotte !</h2>
                        </div>

                        <div className="blog-home__top-text">
                            Nous pouvons nous occuper du nettoyage de votre flotte de véhicule. <br /> N'hésitez pas à nous contacter pour une offre sur mesure.
                        </div>
                    </div>
                </div>
            </section>
            <div className="client-carousel ">
                <div className="container">
                    <p className='mb-5'>Ils nous font confiance :</p>
                    <Swiper
                        className="swiper-container thm-swiper__slider"
                        modules={[Autoplay]}
                        spaceBetween={140}
                        slidesPerView={5}
                        autoplay={{ delay: 10000 }}
                        breakpoints={{
                            "0": {
                                "spaceBetween": 30,
                                "slidesPerView": 2
                            },
                            "375": {
                                "spaceBetween": 30,
                                "slidesPerView": 2
                            },
                            "575": {
                                "spaceBetween": 30,
                                "slidesPerView": 3
                            },
                            "767": {
                                "spaceBetween": 50,
                                "slidesPerView": 4
                            },
                            "991": {
                                "spaceBetween": 50,
                                "slidesPerView": 5
                            },
                            "1199": {
                                "spaceBetween": 140,
                                "slidesPerView": 5
                            }
                        }}
                    >
                        <div className="swiper-wrapper">
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <img src="assets/images/clients/yohann-florentin-architecte.png" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <img src="assets/images/clients/tdf.webp" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <img src="assets/images/clients/grands-projets-de-polynesie.jpg" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <img src="assets/images/clients/cipac-tahiti.webp" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <img src="assets/images/clients/brasserie-de-tahiti.png" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <img src="assets/images/clients/dtt.jpg" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <img src="assets/images/clients/banque-de-polynesie.jpg" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <img src="assets/images/clients/repar-ton-phone.png" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <img src="assets/images/clients/tahiti-crew.jpeg" alt="" />
                                </div>
                            </SwiperSlide>
                        </div>
                    </Swiper>
                </div>
            </div>

            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link >

            {ytShow &&

                <>
                    <div className="mfp-bg mfp-fade mfp-ready" style={{ height: '8367px', position: 'absolute' }}></div>

                    <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready" tabIndex={-1} style={{ top: '3807px', position: 'absolute', height: '298px' }}>
                        <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                            <div className="mfp-content">
                                <div className="mfp-iframe-scaler">
                                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setytShow(false)}>×</button>
                                    <iframe className="mfp-iframe" title="video-1" src="//www.youtube.com/embed/y2Eqx6ys1hQ?autoplay=1" frameBorder="0" allowFullScreen>
                                    </iframe></div></div><div className="mfp-preloader">Loading...</div>
                        </div>
                    </div>
                </>}

        </>
    )
}

export default Home