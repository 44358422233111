import React, { useEffect, useLayoutEffect, useState } from 'react';

import { AutoComplete, Input, Modal, Select } from 'antd';
import { iconUrlByType, placeholderBrandByType, placeholderModelByType, placeholderPlateByType } from '../utils/vehicleTypes';
import { useStores } from '../stores';
import toast from 'react-hot-toast';
import { fromNow } from '../utils/dateUtils';

const VehicleCard = ({
    vehicle = null,
    selectMode = false,
    selected = false,
    onAdd,
    onSelect
}
    :
    {
        vehicle?: any,
        selectMode?: boolean,
        selected?: boolean,
        onAdd?: Function,
        onSelect?: Function
    }) => {

    const [vehicleLogo, setVehicleLogo] = useState<string>('')

    const { carStore, apiStore, userStore } = useStores()

    const [modalOpen, setModalOpen] = useState(false);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [value, setValue] = useState('');
    const [brandOptions, setBrandOptions] = useState<{ value: string }[]>([]);

    const [apiModelsByBrand, setApiModelsByBrand] = useState([]);
    const [modelOptions, setModelOptions] = useState<{ value: string }[]>([]);

    const [plate, setPlate] = useState('')
    const [vehicleType, setVehicleType] = useState(null)
    const [category, setCategory] = useState(null)
    const [brand, setBrand] = useState('')
    const [model, setModel] = useState('')
    const [color, setColor] = useState('')

    useEffect(() => {
        if (modalOpen == false) {
            // Modal has been closed
            setVehicleType(null)
            setCategory(null)
            resetVehicleInputs()
        }
    }, [modalOpen])

    useEffect(() => {
        setCategory(null)
        resetVehicleInputs()
    }, [vehicleType])

    useEffect(() => {
        setModel('')
    }, [brand])

    useEffect(() => {
        if (vehicle?.vehicleType) {
            setVehicleLogo("https://www.carlogos.org/car-logos/" + vehicle.brand.toLowerCase() + "-logo.png")
        } else {
            setVehicleLogo(iconUrlByType(vehicle?.vehicleType))
        }
    }, [])

    const _handleClick = () => {
        if (typeof onSelect == 'function') {
            onSelect()
        }
    }

    const resetVehicleInputs = () => {
        setCategory(null)
        setBrand('')
        setModel('')
        setColor('')
        setPlate('')
    }

    const loadMatchingModels = () => {
        apiStore.post('car/models/' + brand, {}).then((models: any) => {
            setApiModelsByBrand(models)
        }).catch(err => {
            console.log(err)
        })
    }

    const updateModel = (text, option) => {
        setModel(text)
        if(option.category){
            setCategory(option.category)
        }
    }

    const deleteVehicle = () => {
        setModalDeleteOpen(true)
    }

    const handleDeleteVehicle = () => {
        setConfirmLoading(true)
        apiStore.post('vehicle/delete/' + vehicle.id, {}).then(vehicles => {
            toast.success('Votre véhicule a bien été supprimé')
            userStore.setVehicles(vehicles)
            setModalDeleteOpen(false)
        }).catch(err => {
            toast.error('Impossible de supprimer votre véhicule')
            console.log(err)
        }).finally(() => {
            setConfirmLoading(false)
        })
    }

    const getOptions = () => {
        return carStore.categoriesByType(vehicleType)
    }

    const onSearchBrand = (searchText: string) => {
        if (searchText == '' || vehicleType != 'auto') {
            return setBrandOptions([])
        } else {
            let rs = carStore.brands.filter((brand: any) => brand.name.toLowerCase().includes(searchText.toLowerCase()))
            return setBrandOptions(rs.map((item: any) => {
                return { value: item.name }
            }))
        }
    };

    const onSearchModel = (searchText: string) => {
        if (searchText == '' || vehicleType != 'auto') {
            return setModelOptions([])
        } else {
            let rs = apiModelsByBrand.filter((model: any) => model.name.toLowerCase().includes(searchText.toLowerCase()))
            return setModelOptions(rs.map((item: any) => {
                return { 
                    category: item.category,
                    value: item.name
                }
            }))
        }
    };

    const renderNullVehicle = () => {
        return (
            <div className="vehicle-add" onClick={() => setModalOpen(true)}>
                <div>
                    <p className='text-center'><i className="fas fa-plus"></i></p>
                    <p>Ajouter un véhicule</p>
                </div>
            </div>
        )
    }

    const renderVehicle = () => {
        return (
            <div className={"vehicle-card " + (selectMode ? 'vehicle-card-select' : '') + (selected ? ' vehicle-card-selected' : '')} onClick={_handleClick}>
                {selected && <img src="assets/images/icons/vehicle-check.png" className='vehicle-selected-icon' />}
                <div className="vehicle-card__plate">
                    {vehicle.plate}
                </div>
                <div className="vehicle-card__bottom">
                    <ul className="vehicle-card__list list-unstyled">
                        <li className='vehicle-card__maker position-relative'>
                            <img src={vehicleLogo} alt='' onError={() => setVehicleLogo(iconUrlByType(vehicle?.vehicleType))} />
                            <p>{vehicle.brand} {vehicle.model}</p>
                            {/* <span className='vehicle-card__category'>{carStore.categoryByKey(vehicle.category)}</span> */}
                        </li>
                        {!selectMode && <li className='added'>
                            Ajouté {fromNow(vehicle.createdAt)}
                        </li>}
                        {selectMode && <li className={'added' + (selected ? ' text-success' : '')}>
                            {selected ? 'Sélectionné' : 'Cliquez pour sélectionner ce véhicule '}
                        </li>}
                    </ul>
                    {!selectMode && <p className='delete-vehicle-btn' onClick={() => deleteVehicle()}>
                        Supprimer
                    </p>}
                </div>
            </div>
        )
    }

    const isDisabledSubmit = () => {
        return !vehicleType || plate.length < 3 || !category || brand == '' || model == '' || color == ''
    }

    const handleOk = async () => {
        setConfirmLoading(true)
        let payload = {
            plate,
            vehicleType,
            category,
            brand,
            model,
            color
        }

        apiStore.post('vehicle/add', payload).then(vehicle => {
            toast.success('Votre véhicule a bien été enregistré')
            resetVehicleInputs()
            if (typeof onAdd == 'function') {
                onAdd(vehicle)
            }
            setModalOpen(false)
        }).catch(err => {
            toast.error('Impossible d\'ajouter votre véhicule')
            console.log(err)
        }).finally(() => {
            setConfirmLoading(false)
        })
    };

    return (
        <>
            {vehicle ?
                renderVehicle()
                :
                renderNullVehicle()
            }
            {/* MODAL DELETE */}
            <Modal
                open={modalDeleteOpen}
                onOk={handleDeleteVehicle}
                confirmLoading={confirmLoading}
                onCancel={() => setModalDeleteOpen(false)}
                okText="Supprimer"
                cancelText="Annuler"
                maskStyle={{ borderRadius: 0 }}
                okButtonProps={{ className: 'ant-btn-danger' }}
            >
                <h5 className="vehicle-modal-title text-danger">Supprimer un véhicule</h5>
                <p className='my-4 text-secondary fw-normal'>Souhaitez-vous supprimer le véhicule suivant ? <b className='text-uppercase'>{vehicle?.brand} {vehicle?.model} - {vehicle?.plate}</b> </p>
            </Modal>

            {/* MODAL ADD */}
            <Modal
                centered={true}
                open={modalOpen}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={() => setModalOpen(false)}
                okText="Valider"
                cancelText="Annuler"
                maskStyle={{ borderRadius: 0 }}
                okButtonProps={{ disabled: isDisabledSubmit() }}
            >
                <h5 className="vehicle-modal-title">Ajouter un véhicule</h5>
                <p className='my-4 text-secondary fw-normal'>Renseignez les informations sur votre véhicule. Ces informations seront stockées de manière sécurisée et ne seront pas partagées.</p>

                <div className='row'>
                    <div className='col-lg-6'>
                        <p className='mb-1'>Type de véhicule</p>
                        <Select
                            value={vehicleType}
                            style={{ width: '100%' }}
                            onChange={choice => { setVehicleType(choice) }}
                            placeholder='Choisissez le type de véhicule'
                            options={[
                                { value: 'auto', label: 'Auto' },
                                { value: 'moto', label: 'Moto' },
                                // { value: 'bateau', label: 'Bateau' }
                            ]}
                        />
                    </div>
                    <div className='col-lg-6'>
                        <p className='mb-1 mt-3 mt-lg-0'>Catégorie</p>
                        <Select
                            value={category}
                            style={{ width: '100%' }}
                            onChange={choice => { setCategory(choice) }}
                            options={getOptions()}
                            disabled={getOptions().length == 0}
                            placeholder='Choisissez une catégorie'
                        />
                    </div>
                </div>

                {category && carStore.categoryByKey(category).description && <div className="alert alert-secondary my-3" style={{ fontSize: 12 }} role="alert">
                    {carStore.categoryByKey(category).description}
                </div>}

                <p className='mb-1 mt-3'>Marque</p>
                <AutoComplete
                    value={brand}
                    options={brandOptions}
                    style={{ width: '100%' }}
                    onSearch={onSearchBrand}
                    onChange={text => setBrand(text)}
                    placeholder={placeholderBrandByType(vehicleType)}
                    onBlur={() => setBrandOptions([])}
                />
                <p className='mb-1 mt-3'>Modèle</p>
                <AutoComplete
                    value={model}
                    options={modelOptions}
                    style={{ width: '100%' }}
                    onSearch={onSearchModel}
                    onChange={(text, option) => updateModel(text, option)}
                    placeholder={placeholderModelByType(vehicleType)}
                    onFocus={() => loadMatchingModels()}
                    onBlur={() => setModelOptions([])}
                />

                <p className='mb-1 mt-3'>Immatriculation</p>
                <Input placeholder={placeholderPlateByType(vehicleType)} value={plate} onChange={e => setPlate(e.target.value)} />

                <p className='mb-1 mt-3'>Couleur</p>
                <Input placeholder="Couleur du véhicule" value={color} onChange={e => setColor(e.target.value)} />
            </Modal>
        </>
    )

}

export default VehicleCard