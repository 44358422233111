import {
    makeAutoObservable
} from 'mobx';
import moment from 'moment';
import { RootStore } from '.';

export default class CarStore {

    private rootStore: RootStore;

    brands: any = []
    models: any = []

    categories: any = {}

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.fetchCatgeories()
    }

    async fetchBrands() {
        const { apiStore } = this.rootStore
        return new Promise(async (resolve, reject) => {
            try {
                let brands = this.getStoredBrands()
                // Check if brands are already in cache
                let cacheDate: any = localStorage.getItem('@dct_brands_loaded_at')
                if (brands != null && cacheDate) {
                    cacheDate = moment(JSON.parse(cacheDate))
                    // Brand cache lasts 5 days
                    if (moment().diff(cacheDate, 'days') < 5) {
                        this.setBrands(brands)
                        resolve(brands)
                        return
                    }
                }

                // Load brands from API
                let resp: any = await apiStore.post("car/brands", null);
                this.brands = resp
                this.setBrands(resp)
                resolve(resp)
            } catch (error) {
                this.removeStoredBrands()
                console.log(error)
                reject(error)
            }
        })
    }

    async fetchCatgeories(){
        const { apiStore } = this.rootStore
        return new Promise(async (resolve, reject) => {
            try {
                // let categories = this.getStoredCategories()
                // // Check if categories are already in cache
                // let cacheDate: any = localStorage.getItem('@dct_categories_loaded_at')
                // if (categories != null && cacheDate) {
                //     cacheDate = moment(JSON.parse(cacheDate))
                //     // Categories cache lasts 2 days
                //     if (moment().diff(cacheDate, 'days') < 2) {
                //         this.setCategories(categories)
                //         resolve(categories)
                //         return
                //     }
                // }

                // Load categories from API
                let resp: any = await apiStore.post("vehicle/categories", null);
                this.categories = resp
                this.setCategories(resp)
                resolve(resp)
            } catch (error) {
                this.removeStoredCategories()
                console.log(error)
                reject(error)
            }
        })
    }

    async setBrands(brands: any) {
        this.brands = brands
        if (brands) {
            localStorage.setItem('@dct_brands', JSON.stringify(brands))
            localStorage.setItem('@dct_brands_loaded_at', JSON.stringify(new Date()))
        } else {
            localStorage.removeItem('@dct_brands')
        }
    }

    async setCategories(categories: any) {
        this.categories = categories
        if (categories) {
            localStorage.setItem('@dct_categories', JSON.stringify(categories))
            localStorage.setItem('@dct_categories_loaded_at', JSON.stringify(new Date()))
        } else {
            localStorage.removeItem('@dct_categories')
        }
    }

    getStoredCategories() {
        const jsonValue = localStorage.getItem('@dct_categories')
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    }

    getStoredBrands() {
        const jsonValue = localStorage.getItem('@dct_brands')
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    }

    removeStoredBrands() {
        this.brands = null
        localStorage.removeItem('@dct_brands')
    }

    removeStoredCategories() {
        this.brands = null
        localStorage.removeItem('@dct_categories')
    }

    categoriesByType(type: string|null) {
        if(type == null){
            return []
        }
        return Object.entries(this.categories[type]).map((arr: any) => ({value: arr[0], label: arr[1].name, description: arr[1].description}))
    }

    categoryByKey(key: string) {
        // Search in each category
        if(this.categories.auto[key]){
            return this.categories.auto[key]
        }else if(this.categories.moto[key]){
            return this.categories.moto[key]
        }else if(this.categories.bateau[key]){
            return this.categories.bateau[key]
        }
    }
}
