import BGPh1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import BGFaq from '../../assets/images/backgrounds/faq-sidebar-b.jpg'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useStores } from '../../stores'

import * as DOMPurify from 'dompurify';

const Faqs = () => {

    const { apiStore } = useStores()

    const [clicked, setClicked] = useState(0);
    const [faqs, setFaqs] = useState<any>([])

    useEffect(() => {
        apiStore.post('faq').then(resp => {
            setFaqs(resp)
        })
    }, [])

    return (
        <>

            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPh1})` }}></div>

                <div className="container">
                    <h2>Foire aux questions</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Accueil</Link></li>
                        <li>/</li>
                        <li><span>Foire aux questions</span></li>
                    </ul>
                </div>
            </section>

            <div className="faq-one">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="thm-accordion" id="thm-accordion-one">
                                {faqs.map((item: any, index: any) => (
                                    <div className={`thm-accordion__item wow fadeInUp ${index === clicked && "active-item"}`} key={index} data-wow-delay="0ms">
                                        <div className="thm-accordion__title" onClick={() => setClicked(index)}>{item.question}

                                            <div className="thm-accordion__icon"></div>
                                        </div>
                                        {index === clicked && <div className="thm-accordion__content">
                                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.answer) }} />
                                        </div>}
                                    </div>
                                ))}

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="faq-one__call" style={{ backgroundImage: `url(${BGFaq})` }}>
                                <h3 className="faq-one__call-title">Contactez-nous</h3>
                                <p className="faq-one__call-text">Des questions ? Besoin d'assistance pour réserver ? Appelez-nous !</p>
                                <a className="faq-one__call-number" href="tel:+68989502319">89 50 23 19</a>
                                <a className="faq-one__call-number" href="tel:+68989402320">89 40 23 20</a>
                                <a className="thm-btn" href="tel:+68989502319">
                                    Appeler
                                    <i className="fas fa-phone-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>

        </>
    )
}

export default Faqs