import { createContext, useContext } from "react"
import ApiStore from "./ApiStore"
import AppStore from "./AppStore"
import CarStore from "./CarStore"
import GeoStore from "./GeoStore"
import UserStore from "./UserStore"

export class RootStore {
  
  public apiStore: ApiStore
  public appStore: AppStore
  public userStore: UserStore
  public carStore: CarStore
  public geoStore: GeoStore

  constructor() {
    this.apiStore = new ApiStore(this)
    this.appStore = new AppStore(this)
    this.userStore = new UserStore(this)
    this.carStore = new CarStore(this)
    this.geoStore = new GeoStore(this)
  }

  getStores(){
    return {
      apiStore: this.apiStore,
      appStore: this.appStore,
      userStore: this.userStore,
      carStore: this.carStore,
      geoStore: this.geoStore
    }
  }
}

export const rootStore = new RootStore()
export const StoreContext = createContext(rootStore)
export const useStores = () => useContext(StoreContext)