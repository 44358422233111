import { Link } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'

import { Navigation, Autoplay, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import BGImg3 from '../assets/images/backgrounds/main-slider-2-2.jpg'
import BGImg2 from '../assets/images/backgrounds/main-slider-2-1.jpg'
import { useStores } from '../stores';
import { observer } from 'mobx-react';

import * as DOMPurify from 'dompurify';
import { siteAsset } from '../utils/settings';

const ProFranchises = () => {

    const { appStore } = useStores()

    return (
        <>
            <div>

                <div className="stricky-header stricked-menu main-menu">
                    <div className="sticky-header__content"></div>
                </div>

                <section className="main-slider">
                    <Swiper
                        className="swiper-container thm-swiper__slider"
                        modules={[Navigation, Autoplay]}
                        slidesPerView={1}
                        loop={false}
                    >

                        <div className="swiper-wrapper">
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="image-layer" style={{ backgroundImage: `url(${BGImg3})` }}>
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <h2 className='mb-0 text-center'>Lavage auto éco-durable pour les professionnels</h2>
                                                <p className="my-4 text-white text-center">Nos supers génis du lavage sans eau interviennent partout dans la zone urbaine de Tahiti. Dropless Carwash Tahiti est le partenaire idéal pour les sociétés désireuses d’entretenir leurs flottes de véhicules. Nous proposons un service sur mesure et de qualité.  Valorisez votre entreprise avec nous.</p>

                                                <div className="d-flex">
                                                    <Link to="/contact" className="thm-btn m-auto">Nous contacter<i className="far fa-arrow-alt-circle-right"></i></Link >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </div>
                    </Swiper>
                </section>

                <section className="service-home-two" style={{ backgroundColor: '#eef3f7', paddingBottom: 30 }}>
                    <div className="container">
                        <div className="section-title text-center">
                            <span className="section-title__tagline">DROPLESS TAHITI</span>
                            <h2 className="section-title__title">Vous êtes...</h2>
                        </div>
                    </div>
                </section>

                {appStore.proCategories.map((proCategory, index) => {

                    const isOdd = index %2 == 0

                    return <section className="service-details" style={{ backgroundColor: isOdd ? '#eef3f7' : 'white' }}>
                        <div className='container'>
                            <div className="row flex-lg">
                                <div className="col-12 col-md-8">
                                    <div className="about-two__content">
                                        <div className="section-title">
                                            <h2 className="section-title__title">{proCategory.title}</h2>
                                        </div>
                                        <div className="about-two__summery" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(proCategory.content) }}></div>
                                        <br />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 d-flex">
                                    <img className='m-auto' style={{ maxWidth: '80%', borderRadius: 20 }} src={siteAsset(proCategory.picture)} />
                                </div>
                            </div>
                        </div>
                    </section>
                })}

                <section className="service-home-two" style={{ backgroundColor: '#eef3f7' }}>
                    <div className="container">
                        <div className="section-title text-center">
                            <span className="section-title__tagline"></span>
                            <h2 className="section-title__title">Combien pouvez-vous économiser par mois ?</h2>
                        </div>

                        <div className="row ">
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <div className="service-card">
                                    <div className="service-card__image">
                                        <img style={{ height: 200, objectFit: 'cover' }} src="assets/images/pro/happy_water.png" alt="" />
                                    </div>
                                    <div className="service-card__content">
                                        <p className="service-card__text pb-2">
                                            Basés sur un lavage par semaine de 10 petites voitures, le nombre de litres d’eau économisé en nous choisissant
                                        </p>
                                        <h3 className="service-card__title pb-3">
                                            6 300 L
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <div className="service-card">
                                    <div className="service-card__image">
                                        <img style={{ height: 200, objectFit: 'cover' }} src="assets/images/pro/happy_timer.png" alt="" />
                                    </div>
                                    <div className="service-card__content">
                                        <p className="service-card__text pb-2">
                                            Basés sur un lavage par semaine de 10 petites voitures, le nombre de minutes économisé en faisant appel à nos services mobiles
                                        </p>
                                        <h3 className="service-card__title pb-3">
                                            4 800 Minutes
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <div className="service-card">
                                    <div className="service-card__image">
                                        <img style={{ height: 200, objectFit: 'cover' }} src="assets/images/pro/happy_money.png" alt="" />
                                    </div>
                                    <div className="service-card__content">
                                        <p className="service-card__text pb-2">
                                            Basés sur un lavage par semaine de 10 petits véhicules, les économies sur votre budget (Salaire moyen du conducteur 1000 CFP/h)
                                        </p>
                                        <h3 className="service-card__title pb-3">
                                            40 000 CFP
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="service-details">
                    <div className="container">
                        <h3 className="service-details__title">Franchise</h3>
                        <p className="service-details__text">
                            Dropless Carwash, spécialiste du lavage sans eau à domicile, intervient en entreprises, administrations et particuliers. Le lavage auto est un marché très dense sur lequel nous avons réussi à nous positionner en étant le premier à proposer du sans eau au Fenua. Aujourd’hui leader sur la Polynésie Française, nous avons continué à étoffer notre gamme de produits pour fournir une meilleure qualité, performance et écologie.
                        </p>
                        <br />
                    </div>
                </section>


                <section className="call-to-action">
                    <div className="container">
                        <h3 className="call-to-action__title">Vous aimez le concept ?</h3>

                        <div className="call-to-action__btn-wrap">
                            <a href="mailto:contact@droplesstahiti.com" className="thm-btn white">
                                Contactez-nous !
                                <i className="far fa-arrow-alt-circle-right"></i>
                            </a >
                        </div>
                    </div>
                </section>

            </div>
            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        </>
    )
}

export default observer(ProFranchises)