import {
    action,
    makeAutoObservable,
    observable
} from 'mobx';
import { RootStore } from '.';
import { isExpiredToken } from '../utils/tokenUtils';

export default class UserStore {

    private rootStore: RootStore;

    // Observables
    user: any = null
    vehicles: any = []
    addresses: any = []
    bookings: any = undefined

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);

        // Fetch user if stored in local
        this.autoconnect();
    }

    async autoconnect() {
        return new Promise(async (resolve, reject) => {
            let storedUser = this.getStoredUser()
            if (storedUser && !isExpiredToken(storedUser.token)) {
                // Stored user is still valid, so we can use it
                await this.setUser(storedUser)
                await this.fetchUser()
                await this.fetchVehicles()
                await this.fetchAddresses()
                resolve(this.user)
            } else {
                // Token is expiring in less than 5 minutes, so we will invalidate stored user data
                this.removeStoredUser()
                resolve(null)
            }
        })
    }

    async fetchVehicles() {
        const { apiStore } = this.rootStore
        return new Promise(async (resolve, reject) => {
            try {
                let resp: any = await apiStore.post("vehicle/list", null);
                this.vehicles = resp
                resolve(resp)
            } catch (error) {
                this.vehicles = []
                console.log(error)
                reject(error)
            }
        })
    }

    async fetchAddresses() {
        const { apiStore } = this.rootStore
        return new Promise(async (resolve, reject) => {
            try {
                let resp: any = await apiStore.post("address/list", null);
                this.addresses = resp
                resolve(resp)
            } catch (error) {
                this.addresses = []
                console.log(error)
                reject(error)
            }
        })
    }

    async fetchBookings() {
        const { apiStore } = this.rootStore
        return new Promise(async (resolve, reject) => {
            try {
                let resp: any = await apiStore.post("booking/list", null);
                this.bookings = resp
                resolve(resp)
            } catch (error) {
                this.bookings = []
                console.log(error)
                reject(error)
            }
        })
    }

    async fetchUser() {
        const { apiStore } = this.rootStore
        return new Promise(async (resolve, reject) => {
            try {
                let resp: any = await apiStore.post("user/me", null);
                this.user = {
                    ...this.user,
                    ...resp
                }
                this.setUser(this.user)
                resolve(this.user)
            } catch (error) {
                this.removeStoredUser()
                console.log(error)
                reject(error)
            }
        })
    }

    async setUser(user: any) {
        this.user = user
        if (user && user.id) {
            localStorage.setItem('@dct_user', JSON.stringify(user))
        } else {
            localStorage.removeItem('@dct_user')
        }
    }

    updateUser(user: any) {
        let fullUser = { ...this.user, ...user }
        this.setUser(fullUser)
    }

    async setTokens(tokens: { token: string, refresh_token: string }) {
        this.user.token = tokens.token
        this.user.refresh_token = tokens.refresh_token
        this.setUser(this.user)
    }

    getStoredUser() {
        const jsonValue = localStorage.getItem('@dct_user')
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    }

    removeStoredUser() {
        this.user = null
        localStorage.removeItem('@dct_user')
    }

    async disconnect() {
        this.removeStoredUser()
        this.setUser(null)
    }

    getUser() {
        return this.user
    }

    firstName() {
        return this.user?.firstName ? this.user.firstName : "Anonyme"
    }

    id() {
        return this.user?.id
    }

    addVehicle(vehicle: any) {
        this.vehicles = [...this.vehicles, vehicle]
    }

    setVehicles(vehicles: any) {
        this.vehicles = vehicles
    }

    addAddress(address: any) {
        this.addresses = [...this.addresses, address]
    }

    setAddresses(addresses: any) {
        this.addresses = addresses
        return this.addresses
    }

    mergeAddresses(addresses: any) {
        this.addresses = [...this.addresses, ...addresses]
        return this.addresses
    }
}
