import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import Navbar from '../pages/Navbar'
import Footer from '../pages/Footer/Main'
import Error from '../pages/Error/Main'
import About from '../pages/About/About'
import Contact from '../pages/Contact/Main'
import Faqs from '../pages/Faqs/Main'
import Gallery from '../pages/Gallery/Main'
import Help from '../pages/Help/Main'
import Home from '../pages/Home/Main'
import Home2 from '../pages/Home2/Main'
import NewsMain from '../pages/News/NewsMain/Main'
import NewsDetails from '../pages/News/NewsDetails/Main'
import OnlineBooking from '../pages/OnlineBooking/Main'
import Pricing from '../pages/Pricing/Main'
import Services from '../pages/Service/Services/Main'
import ServiceDetails from '../pages/Service/ServiceDetails/Main'
import Shops from '../pages/Shop/Shops/Main'
import ShopDetails from '../pages/Shop/ShopDetails/Main'
import Connexion from '../pages/Connexion'
import Inscription from '../pages/Inscription'
import MonCompte from '../pages/MonCompte'
import ProtectedRoute from '../guards/ProtectedRoute'
import { useStores } from '../stores'
import { inject, Observer, observer } from 'mobx-react'
import BookingConfirmation from '../pages/BookingConfirmation'
import Parametres from '../pages/Parametres'
import MentionsLegales from '../pages/Legal/MentionsLegales'
import ConditionsGenerales from '../pages/Legal/ConditionsGenerales'
import ProFranchises from '../pages/ProFranchises'


const Routing = inject("userStore")(observer(() => {

    const { userStore } = useStores()
    const { user } = userStore

    return (
        <Observer>{() => (
            <>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    {/* <Route path="/home2" element={<Home2 />} /> */}
                    <Route path="/error" element={<Error />} />
                    <Route path="/about" element={<About />} />
                    {/* <Route path="/contact" element={<Contact />} /> */}
                    <Route path="/faqs" element={<Faqs />} />
                    {/* <Route path="/gallery" element={<Gallery />} /> */}
                    {/* <Route path="/help" element={<Help />} /> */}
                    {/* <Route path="/newsmain" element={<NewsMain />} /> */}
                    {/* <Route path="/newsdetails" element={<NewsDetails />} /> */}
                    <Route path="/pricing" element={<Pricing />} />
                    {/* <Route path="/services" element={<Services />} /> */}
                    {/* <Route path="/servicedetails" element={<ServiceDetails />} /> */}
                    {/* <Route path="/shops" element={<Shops />} /> */}
                    {/* <Route path="/shopdetails" element={<ShopDetails />} /> */}

                    <Route path="/pro" element={<ProFranchises />} />

                    <Route path="/connexion" element={<Connexion />} />
                    <Route path="/inscription" element={<Inscription />} />

                    <Route element={<ProtectedRoute isAllowed={!!user} />}>
                        <Route path="/mon-compte" element={<MonCompte />} />
                    </Route>

                    <Route element={<ProtectedRoute isAllowed={!!user} />}>
                        <Route path="/parametres" element={<Parametres />} />
                    </Route>

                    <Route path="/mentions-legales" element={<MentionsLegales />} />
                    <Route path="/conditions-generales" element={<ConditionsGenerales />} />

                    <Route path="/prendre-rendez-vous" element={<OnlineBooking />} />
                    <Route path="/reservation/:paymentToken" element={<BookingConfirmation />} />


                    <Route path="/page-introuvable" element={<Error />} />
                    <Route path="*" element={<Error />} />

                </Routes>
                <Footer />
            </>
        )}
        </Observer>
    )
}))

export default Routing