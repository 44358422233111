export const shortenFullAddress = (fullAddress: string) => {
    if(fullAddress.length > 33){
        return fullAddress.slice(0, 30)+'...'
    }else{
        return fullAddress
    }
}

export const shortenAddressLabel = (addressLabel:string) => {
    if(addressLabel.length > 15){
        return addressLabel.slice(0, 12)+'...'
    }else{
        return addressLabel
    }
}