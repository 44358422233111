import BGPh1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import { Autoplay, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React from 'react';
import { BASE_URL, siteAsset } from '../../utils/settings';
import { observer } from 'mobx-react';
import { useStores } from '../../stores';
import * as DOMPurify from 'dompurify';

const About = () => {

    const { appStore } = useStores()

    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPh1})` }}></div>

                <div className="container">
                    <h2>À propos</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">Accueil</Link ></li>
                        <li>/</li>
                        <li><span>À propos</span></li>
                    </ul>
                </div>
            </section>

            <section className="about-one">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src="assets/images/resources/about-1-1.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-lg-6">
                            <img src="assets/images/resources/about-1-2.jpg" alt="" className="img-fluid" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="section-title">
                                <span className="section-title__tagline">DROPLESS CAR WASH TAHITI</span>
                                <h2 className="section-title__title">We are Dropless !</h2>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="about-one__content">
                                <p className="about-one__highlighted">Dropless Carwash, le lavage auto à domicile innovant et éco-responsable unique au Fenua.</p>
                                <p className="about-one__text">
                                    Notre entreprise a été fondée afin de transformer l’industrie du lavage automobile. En effet, optez pour un lavage sans eau pour un futur meilleur. Notre but : Sauver notre Fenua contre le gaspillage d’eau potable et apporter une solution durable à tous les propriétaires de véhicules dans le pacifique. En choisissant Dropless, vous procèderez à des expériences uniques au travers de service exceptionnel de la plus haute qualité. Ensemble, pour un avenir prometteur !
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="team-about pb-0">
                <div className="container">
                    <div className="section-title text-center">
                        <span className="section-title__tagline">NOTRE EXTRAORDINAIRE FAMILLE DROPLESS</span>
                        <h2 className="section-title__title">Nos agents</h2>
                    </div>
                    <div className="row team-card__row justify-content-center">
                        {appStore.agents.map((agent: any) => (
                            <div className="col-lg-6 col-xl-3">
                                <div className="team-card">
                                    <div className="team-card__image">
                                        <img style={{ height: 180, objectFit: 'cover' }} src={siteAsset(agent.picture)} alt="" />
                                    </div>
                                    <div className="team-card__content">
                                        <div className="team-card__social"></div>
                                        <h3>{agent.name}</h3>
                                        <p>{agent.job}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className='team-about'>
                <div className='container'>
                    <div className="section-title text-center">
                        <span className="section-title__tagline">À VOTRE SERVICE DEPUIS 2016</span>
                        <h2 className="section-title__title">Les temps marquants</h2>
                    </div>

                    <ul className="list-unstyled service-details__list">
                        {appStore.events.map((event: any) => (
                            <li>
                                <i className="fa fa-check-circle"></i>
                                <b style={{ fontSize: 25, marginRight: 17 }}>{event.year}</b>{event.description}
                            </li>
                        ))}
                    </ul>
                </div>
            </section>

            <section className="testimonials-one">
                <div className="testimonials-one__boxed">
                    <div className="container">
                        <Swiper
                            className="swiper-container testimonials-one__carousel"
                            modules={[Mousewheel]}
                            loop={false}
                            speed={1400}
                            mousewheel={true}
                            slidesPerView={1}
                            autoplay={{ delay: 5000 }}
                        >
                            {appStore.reviews.map((review: any) => (
                                <div className="swiper-wrapper">
                                    <SwiperSlide>
                                        <div className="swiper-slide">
                                            <p className="testimonials-one__text">
                                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(review.reviewContent) }} />
                                            </p>
                                            <div className="testimonials-two__meta">
                                                <h3 className="testimonials-one__name">{review.clientName}</h3>
                                                <span className="testimonials-one__designation">Client particulier</span>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </div>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>

            <div className="gallery-two">
                <Swiper
                    className="swiper-container thm-swiper__slider"
                    modules={[Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{ delay: 5000 }}
                    breakpoints={{
                        "376": {
                            "spaceBetween": 30,
                            "slidesPerView": 2
                        },
                        "576": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "768": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "992": {
                            "spaceBetween": 30,
                            "slidesPerView": 4
                        },
                        "1200": {
                            "spaceBetween": 30,
                            "slidesPerView": 5
                        }
                    }}
                >
                    <div className="swiper-wrapper">
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="gallery-one__item">
                                    <img src={BASE_URL + 'assets/images/gallery/1.jpeg'} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="gallery-one__item">
                                    <img src={BASE_URL + 'assets/images/gallery/2.jpeg'} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="gallery-one__item">
                                    <img src={BASE_URL + 'assets/images/gallery/3.jpeg'} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="gallery-one__item">
                                    <img src={BASE_URL + 'assets/images/gallery/4.jpeg'} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="gallery-one__item">
                                    <img src={BASE_URL + 'assets/images/gallery/5.jpeg'} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="gallery-one__item">
                                    <img src={BASE_URL + 'assets/images/gallery/6.jpeg'} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="gallery-one__item">
                                    <img src={BASE_URL + 'assets/images/gallery/7.jpeg'} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="gallery-one__item">
                                    <img src={BASE_URL + 'assets/images/gallery/8.jpeg'} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </div>
                </Swiper>
            </div>

            <section className="call-to-action">
                <div className="container">
                    <h3 className="call-to-action__title">Prenez rendez-vous simplement en ligne</h3>

                    <div className="call-to-action__btn-wrap">
                        <Link to="/OnlineBooking" className="thm-btn white">
                            Je réserve
                            <i className="far fa-arrow-alt-circle-right"></i>
                        </Link >
                    </div>
                </div>
            </section>
            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link >
        </>
    )
}

export default observer(About)