
import { Link, useNavigate } from 'react-router-dom'
import { Autoplay, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Form, Nav } from 'react-bootstrap';
import BGPh1 from '../assets/images/backgrounds/page-header-bg-1-1.jpg'
import VehicleCard from '../components/Vehicle';
import { useStores } from '../stores';
import { formatFirstName } from '../utils/stringFormatters';
import { Observer, observer } from 'mobx-react';
import AddressCard from '../components/Address';
import { Card } from 'antd';
import Booking from '../components/Booking';

const MonCompte = observer(() => {

    const navigate = useNavigate()

    const { userStore, carStore } = useStores()

    const [tab, setTab] = useState<any>('vehicles')

    useEffect(() => {
        carStore.fetchBrands()
    }, [])

    useLayoutEffect(() => {
        userStore.fetchVehicles()
        userStore.fetchAddresses()
        userStore.fetchBookings()
    }, [])

    const addVehicle = (vehicle: any) => {
        userStore.addVehicle(vehicle)
    }

    const setAddressList = (addresses: any) => {
        userStore.setAddresses(addresses)
    }

    const settings = () => {
        navigate('/parametres')
    }

    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPh1})` }}></div>

                <div className="container">
                    <div className='row'>
                        <div className='col-lg-6 text-lg-start text-center'>
                            <h2>Ia ora na, {formatFirstName(userStore.user.firstName)}</h2>
                        </div>
                        <div className='col-lg-6 d-flex'>
                            <Link to="/prendre-rendez-vous" className="thm-btn m-auto mt-lg-0 mt-4">
                                Prendre rendez-vous
                                <i className="fas fa-chevron-right"></i>
                            </Link>
                        </div>
                    </div>
                    {/* <ul className="thm-breadcrumb list-unstyled">
                            <li>Déconnexion</li>
                        </ul> */}
                </div>
            </section>

            <section className='py-5'>
                <div className="container">


                    <div className="row">

                        <div className="col-lg-12 d-flex justify-content-between align-items-center">
                            <h5 className="section-title__title">Mon compte</h5>
                            <i className="fas fa-cog" onClick={settings} style={{ fontSize: 25, cursor: 'pointer', color: 'grey' }}></i>
                        </div>

                        <div className='col-lg-12'>
                            <Nav className='mt-4 mt-lg-5 mb-1 mb-lg-4' variant="pills" activeKey={tab} onSelect={tab => setTab(tab)}>
                                <Nav.Item>
                                    <Nav.Link eventKey="vehicles">
                                        Mes véhicules
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="addresses">
                                        Mes adresses
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="bookings">
                                        Mes réservations
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>

                        {tab == 'vehicles' && <div className="col-12">
                            <div className='row'>
                                {userStore.vehicles.map((vehicle: any) => (
                                    <div key={vehicle.id} className='col-md-6 col-lg-4'>
                                        <VehicleCard vehicle={vehicle} />
                                    </div>
                                ))}
                                <div className='col-md-6 col-lg-4'>
                                    <VehicleCard vehicle={null} onAdd={(vehicle: any) => addVehicle(vehicle)} />
                                </div>
                            </div>
                        </div>}

                        {tab == 'addresses' && <div className="col-12">
                            {/* <br />
                                <div className="section-title mb-0">
                                    <br />
                                    <h5 className="section-title__subtitle">Mes adresses</h5>
                                    <hr className='secondary-hr' />
                                </div> */}
                            <div className='row'>
                                {userStore.addresses.map((address: any) => (
                                    <div key={address.id} className='col-md-6 col-lg-4'>
                                        <AddressCard address={address} onUpdate={(addresses: any) => setAddressList(addresses)} />
                                    </div>
                                ))}
                                <div className='col-md-6 col-lg-4'>
                                    <AddressCard address={null} onUpdate={(addresses: any) => setAddressList(addresses)} />
                                </div>
                            </div>
                        </div>}

                        {tab == 'bookings' && <div className="col-12">

                            {/* NO INCOMING */}
                            {userStore.bookings?.incoming?.length == 0 && <>
                                <div className='d-flex justify-content-center align-items-center flex-column'>
                                    <h4 className='mt-4'>Aucune réservation</h4>
                                    <p>C'est ici qu'apparaîtront vos futures réservations</p>
                                    <Link to="/prendre-rendez-vous" className="thm-btn">Prendre rendez-vous <i className="far fa-arrow-alt-circle-right"></i></Link >
                                </div>
                            </>}

                            {/* INCOMING */}
                            {userStore.bookings?.incoming?.length > 0 && <>
                                <h4 className='mt-3 mb-0'>À venir</h4>
                                <div className='row my-3'>
                                    {userStore.bookings?.incoming.map((booking: any) => (
                                        <div key={booking.id} className='col-12'>
                                            <Booking booking={booking} />
                                        </div>
                                    ))}
                                </div>
                            </>}
                            {/* PAST */}
                            {userStore.bookings?.past?.length > 0 && <>
                                <h4 className='mt-3 mb-0'>Historique</h4>
                                <div className='row my-3'>
                                    {userStore.bookings?.past.map((booking: any) => (
                                        <div key={booking.id} className='col-12'>
                                            <Booking booking={booking} />
                                        </div>
                                    ))}
                                </div>
                            </>}
                            {/* CANCELED */}
                            {userStore.bookings?.canceled?.length > 0 && <>
                                <h4 className='mt-3 mb-0'>Annulées</h4>
                                <div className='row my-3'>
                                    {userStore.bookings?.canceled.map((booking: any) => (
                                        <div key={booking.id} className='col-12'>
                                            <Booking booking={booking} />
                                        </div>
                                    ))}
                                </div>
                            </>}
                        </div>}

                    </div>
                </div>
            </section>

            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link >
        </>
    )
})
export default MonCompte