import React, { useEffect, useState } from 'react'
import { useStores } from '../stores'
import { Checkbox } from 'antd'
import { useNavigate } from 'react-router-dom'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

import * as DOMPurify from 'dompurify';

const PricingTable = ({
    selectedTabMenu = null,
    hideTabMenu = false,
    selectedPrice = null,
    onSelectPrice = () => { },
    selectedOptions = null,
    onSelectOptions = () => { },
    canSelectOptions = false
}: {
    selectedTabMenu?: string | null,
    hideTabMenu?: boolean,
    selectedPrice?: any,
    onSelectPrice?: Function,
    selectedOptions?: any,
    onSelectOptions?: Function,
    canSelectOptions?: boolean
}) => {

    const { apiStore, appStore } = useStores()
    const navigate = useNavigate()

    const [tabMenu, tabActive] = useState<any>(selectedTabMenu)
    const [prices, setPrices] = useState<any>([])
    const [options, setOptions] = useState<any>([])
    const [features, setFeatures] = useState<any>([])

    const [stateSelectedOptions, setStateSelectedOptions] = useState<any>([])

    const handlePriceSelect = (price: any) => {
        setStateSelectedOptions([])
        onSelectPrice(price)
    }

    const isSelectedOption = (optionId: any) => {
        let isOptionInSelection = stateSelectedOptions.filter((element: any) => element == optionId).length > 0
        return isOptionInSelection
    }

    const addOptionSelection = (optionId: any, isChecked: boolean) => {
        let isOptionInSelection = isSelectedOption(optionId)
        if (!isOptionInSelection && isChecked) {
            setStateSelectedOptions([
                ...stateSelectedOptions, optionId
            ])
        } else if (!isChecked) {
            setStateSelectedOptions(
                stateSelectedOptions.filter((element: any) => element != optionId)
            )
        }
    }

    // Reset options selection on vehicle change
    useEffect(() => {
        setStateSelectedOptions([])
    }, [tabMenu])

    // Return prices revelant to selected vehicle category
    const activeTabPrices = () => {
        let items = [];
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].slug == tabMenu) {
                return prices[i].prices
            }
        }
        return []
    }

    useEffect(() => {
        tabActive(selectedTabMenu)
    }, [selectedTabMenu])

    const doSomething = (mediaQuery: any) => {
        if (mediaQuery.matches) {
            $('.sep').attr('colSpan', activeTabPrices().length + 1);
        } else {
            $('.sep').attr('colSpan', 2);
        }
    }

    // Fetch prices, features, options
    useEffect(() => {
        // Load pricing
        apiStore.post('pricing').then((resp: any) => {
            // Features
            setFeatures(resp.features)
            appStore.setFeatures(resp.features)
            // Prices
            setPrices(resp.prices)
            appStore.setPrices(resp.prices)
            tabActive(resp.prices[0].slug)
            // Options
            setOptions(resp.options)
            appStore.setOptions(resp.options)
        })
    }, [])

    useEffect(() => {
        // Media query for responsive pricing table
        var mediaQuery = window.matchMedia('(min-width: 992px)');
        // Add a listen event
        mediaQuery.addListener(doSomething);
        // On load
        doSomething(mediaQuery);
        // UL
        $(".mobile-ul").on("click", "li", function () {
            var pos = $(this).index() + 2;
            $("tr").find('td:not(:eq(0))').hide();
            $('td:nth-child(' + pos + ')').css('display', 'table-cell');
            $("tr").find('th:not(:eq(0))').hide();
            $('li').removeClass('active');
            $(this).addClass('active');
        });

        // Click on first tab by default
        $(".mobile-ul").children('li').first().click()
    }, [tabMenu])

    // Check if feature is included in price
    const isActiveFeatureForPrice = (price: any, featureSlug: string) => {
        return (price.prestation.featuresSlugs.includes(featureSlug))
    }

    const optionsBySelectedPrice = (price: any) => {
        if (price == null && !canSelectOptions) {
            return appStore.options
        } else {
            return price.prestation.options
        }
    }

    const optionPriceByVehicleCategory = (optionId: any) => {
        const options = optionsBySelectedPrice(selectedPrice)
        const optionById = options.filter((option: any) => option.id == optionId)[0]
        const optionPrice = optionById.optionPrices.filter((optionPrice: any) => optionPrice.vehicleCategory == tabMenu)[0]
        if(optionPrice){
            return optionPrice.price
        }else{
            return null
        }
    }

    return (
        <>
            <div className="tab-box" id="price-tab">

                {!canSelectOptions && <h2 className="section-title__title text-center">Sélectionnez un type de véhicule</h2>}
                <br />
                <br />
                {!hideTabMenu && <ul className="pricing-one__tab-title tab-box__title list-unstyled">
                    {prices.map((item: any) => (
                        <li key={item.id} className={`pricing-one__tab-title-item ${tabMenu == item.slug && "active-item"}`} onClick={() => tabActive(item.slug)}>
                            <p>{item.name}</p>
                        </li>
                    ))}
                </ul>}

                <div className='responsive-comparison-table'>
                    <ul className='mobile-ul'>
                        {activeTabPrices().map((price: any, index: any) => (
                            <li key={price.id} className={"bg-blue"}>
                                <button>{price.prestation.name}</button>
                            </li>
                        ))}
                    </ul>
                    <table>
                        <thead>
                            <tr>
                                <th className="hide"></th>
                                {activeTabPrices().map((price: any) => (
                                    <th key={price.id} className="bg-blue prestation-name">{price.prestation.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='hide'></td>
                                {activeTabPrices().map((price: any) => {
                                    if (!price.price || price.price <= 0) {
                                        return <td key={price.id} style={{ height: 80 }}><span className="txt-l">SUR DEVIS</span></td>
                                    } else {
                                        return <td key={price.id} style={{ height: 80 }}><span className="txt-l">{(price.price).toLocaleString()}</span><span className="txt-top">XPF</span></td>
                                    }
                                })}
                            </tr>
                            <tr>
                                <td>Durée</td>
                                {activeTabPrices().map((price: any) => (
                                    <td key={price.id}><i className="far fa-clock"></i>&nbsp;&nbsp;{price.time}</td>
                                ))}
                            </tr>
                            <tr>
                                <td className="sep">Prestations</td>
                            </tr>

                            {features.map((feature: any) => {
                                return <tr key={feature.id}>
                                    <td className='feature-description'>{feature.description}</td>
                                    {activeTabPrices().map((price: any) => (
                                        <td key={price.id}><span>{isActiveFeatureForPrice(price, feature.slug) ? <i className="fas fa-check-square text-success"></i> : <i className="fa fa-times text-danger"></i>}</span></td>
                                    ))}
                                </tr>
                            })}

                            {/* <tr>
                                <td>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">
                                        Traitement Nano Cire de carnauba sur carrosserie et matière extérieure du véhicule. Durée de protection : 2 - 3 mois
                                    </Tooltip>}>
                                        <a className='tooltip-trigger'>Traitement nano-cire</a>
                                    </OverlayTrigger>
                                </td>
                                {activeTabPrices().map((price: any) => (
                                    <td><span>{price.prestation.nanoCire && <i className="fa fa-check-circle text-success"></i>}</span></td>
                                ))}
                            </tr> */}

                            {canSelectOptions && <tr>
                                <td className='hide'></td>
                                {activeTabPrices().map((price: any) => (
                                    <td key={price.id} className='select-pricing-btn-book'>
                                        <button onClick={() => handlePriceSelect(price)} className={"thm-btn " + (selectedPrice?.id === price.id ? 'filled' : '')}>
                                            {selectedPrice?.id !== price.id ? 'Sélectionner' : 'Sélectionné'}
                                            {selectedPrice?.id !== price.id && <i className="far fa-arrow-alt-circle-right"></i>}
                                            {selectedPrice?.id === price.id && <i className="fas fa-check"></i>}
                                        </button >
                                    </td>
                                ))}
                            </tr>}
                        </tbody>
                    </table>
                </div>

                {/* {prices.map((item: any) => (
                                <>
                                    <div className={`tab-box__content online-booking py-0 ${tabMenu === item.slug && "active-item"}`} >
                                        <div className="row">
                                            {item.prices.map((price: any) => (
                                                <div className="col-md-12 col-lg-4">
                                                    <div className="price-card">
                                                        <div>
                                                            <p className="price-card__name text-info fw-bold text-uppercase">{price.prestation.name}</p>
                                                            <p className="price-card__amount">
                                                                {(price.price).toLocaleString()}
                                                                <span className="price-card__amount-fraction">XPF</span>
                                                            </p>
                                                            <div className="price-card__duration">
                                                                <i className="far fa-clock"></i>
                                                                {price.time}
                                                            </div>
                                                        </div>
                                                        <div className="price-card__bottom">
                                                            <ul className="price-card__list list-unstyled">
                                                                {price.prestation?.descriptionLines && Object.entries(price.prestation.descriptionLines).map((arr: any, idx) => {
                                                                    return (
                                                                        <li key={idx}>
                                                                            <i className="fa fa-check"></i>
                                                                            {arr[1]}
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                            <Link to="/prendre-rendez-vous" className="thm-btn">Réserver <i className="far fa-arrow-alt-circle-right"></i></Link >
                                                        </div>
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </>
                            ))} */}
            </div>


            {(selectedPrice || !canSelectOptions) && <div id='pricing-table-options-container'>
                <br />
                <br />
                <h2 className="section-title__title text-center">Options</h2>
                <br />
                <br />

                {/* EXTRA OPTIONS */}
                <div className="online-booking__extra">
                    {optionsBySelectedPrice(selectedPrice).map((option: any) => {

                        return (
                            <div key={option.id} className="online-booking__extra-item">
                                <div>
                                    <div className='d-flex flex-row justify-content-center align-items-center'>
                                        {canSelectOptions && <div style={{ marginRight: 30 }}>
                                            <Checkbox disabled={!optionPriceByVehicleCategory(option.id)} checked={isSelectedOption(option.id)} onChange={(e: CheckboxChangeEvent) => addOptionSelection(option.id, e.target.checked)} className='my-auto' />
                                        </div>}
                                        <div>
                                            <h3 className="online-booking__extra-title mb-2">{option.name}</h3>
                                            <div className='mb-0 feature-description option-item' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(option.description) }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="list-unstyled online-booking__extra-list">
                                    <p className="txt-l mb-0 w-100 text-center" style={{ minWidth: 120 }}>
                                        {optionPriceByVehicleCategory(option.id) ? <>{optionPriceByVehicleCategory(option.id)}<small>XPF</small></> : 'Indisponible' } 
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <br />
                {/* CONTINUE BTN */}
                {canSelectOptions && <>
                    {stateSelectedOptions.length > 0 ? <button onClick={() => onSelectOptions(stateSelectedOptions)} className={"thm-btn float-right"}>
                        Valider ma sélection
                    </button> : <button onClick={() => onSelectOptions(stateSelectedOptions)} className={"thm-btn filled cursor-pointer float-right"}>
                        Ignorer les options
                    </button>}
                </>}
            </div>}
        </>
    )

}

export default PricingTable