
import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import BGPh1 from '../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { useStores } from '../stores';
import { formatFirstName } from '../utils/stringFormatters';
import { observer } from 'mobx-react';
import Cleave from 'cleave.js/react';
import { toast } from 'react-hot-toast';

const Parametres = observer(() => {

    const { userStore, apiStore } = useStores()

    // Account edit
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [phone, setPhone] = useState('')

    useEffect(() => {
        if (userStore.user?.id) {
            setFirstname(userStore.user.firstName)
            setLastname(userStore.user.lastName)
            setPhone(userStore.user.phone)
        }
    }, [userStore.user])

    // Password edit
    const [oldPass, setOldPass] = useState('')
    const [newPass1, setNewPass1] = useState('')
    const [newPass2, setNewPass2] = useState('')

    const navigate = useNavigate()

    const account = () => {
        navigate('/mon-compte')
    }

    const updateAccount = () => {
        apiStore.post('user/edit', {
            firstname,
            lastname,
            phone
        }).then(resp => {
            userStore.updateUser(resp)
            toast.success('Vos informations ont bien été mises à jour')
        }).catch(err => {
            toast.error('Une erreur est survenue, impossible de mettre à jour vos informations')
        })
    }

    const updatePassword = () => {
        apiStore.post('user/edit-password', {
            password: newPass1,
            oldPassword: oldPass
        }).then(resp => {
            toast.success('Votre mot de passe a bien été mis à jour')
        }).catch(err => {
            toast.error('Une erreur est survenue, votre mot de passe actuel est invalide')
        }).finally(() => {
            setOldPass('')
            setNewPass1('')
            setNewPass2('')
        })
    }

    const canEditPassword = () => {
        return oldPass.length > 0 && newPass1.length > 5 && newPass1 == newPass2 && oldPass !== newPass1
    }

    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPh1})` }}></div>

                <div className="container">
                    <div className='row'>
                        <div className='col-lg-6 text-lg-start text-center'>
                            <h2>Ia ora na, {formatFirstName(userStore.user.firstName)}</h2>
                        </div>
                        <div className='col-lg-6 d-flex'>
                            <Link to="/prendre-rendez-vous" className="thm-btn m-auto mt-lg-0 mt-4">
                                Prendre rendez-vous
                                <i className="fas fa-chevron-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-5'>
                <div className="container">


                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-between align-items-center">
                            <h5 className="section-title__title">Paramètres</h5>
                            <p className='mb-0 cursor-pointer' onClick={account}><i className="far fa-user"></i> Retour à mon compte</p>
                        </div>
                    </div>

                    <br />
                    <hr />
                    <h4>Informations de contact</h4>
                    <p className='mb-0'>Ces informations seront utilisées par défaut sur vos réservations à venir</p>

                    <div className="row dct-fields">
                        <div className='col-lg-6'>
                            <Form.Group>
                                <Form.Label className='dct-label  mt-3'>Prénom</Form.Label>
                                <Form.Control value={firstname} onChange={e => setFirstname(e.target.value)} type="text" placeholder="Votre prénom" />
                            </Form.Group>
                        </div>
                        <div className='col-lg-6'>
                            <Form.Group>
                                <Form.Label className='dct-label mt-3'>Nom</Form.Label>
                                <Form.Control value={lastname} onChange={e => setLastname(e.target.value)} type="text" placeholder="Votre nom" />
                            </Form.Group>
                        </div>
                        <div className='col-lg-6'>
                            <Form.Group>
                                <Form.Label className='dct-label mt-3'>N° de téléphone</Form.Label>
                                {/* <Form.Control onChange={e => setPhone(e.target.value)} type="number" placeholder="89 XX XX XX" /> */}
                                <Cleave
                                    value={phone}
                                    placeholder="89 XX XX XX"
                                    options={{
                                        blocks: [2, 2, 2, 2],
                                        delimiter: " ",
                                        numericOnly: true
                                    }}
                                    onChange={e => setPhone(e.target.rawValue)}
                                    className="form-control"
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="mt-4">
                        <button disabled={apiStore.isLoading} className="thm-btn mobile-full-width" onClick={updateAccount}>
                            <span>Enregistrer</span>
                        </button>
                    </div>

                    <br />
                    <hr />
                    <h4>Modifier mon mot de passe</h4>
                    <p className='mb-0'>Afin de modifier votre mot de passe, vous devez saisir votre mot de passe actuel et saisir votre nouveau mot de passe deux fois</p>
                    <div className="row dct-fields">
                        <div className='col-12'>
                            <Form.Group>
                                <Form.Label className='dct-label  mt-3'>Mot de passe actuel</Form.Label>
                                <Form.Control value={oldPass} onChange={e => setOldPass(e.target.value)} type="password" placeholder="Saisissez votre mot de passe actuel" />
                            </Form.Group>
                        </div>
                        <div className='col-12'>
                            <Form.Group>
                                <Form.Label className='dct-label  mt-3'>Nouveau mot de passe</Form.Label>
                                <Form.Control value={newPass1} onChange={e => setNewPass1(e.target.value)} type="password" placeholder="Saisissez votre nouveau mot de passe (6 caractères min)" />
                            </Form.Group>
                        </div>
                        <div className='col-12'>
                            <Form.Group>
                                <Form.Label className='dct-label  mt-3'>Confirmez votre nouveau mot de passe</Form.Label>
                                <Form.Control value={newPass2} onChange={e => setNewPass2(e.target.value)} type="password" placeholder="Confirmez votre nouveau mot de passe" />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="mt-4">
                        <button disabled={!canEditPassword()} onClick={updatePassword} className="thm-btn mobile-full-width">
                            {!apiStore.isLoading && <span>Modifier mon mot de passe<i className="fas fa-lock"></i></span>}
                            {apiStore.isLoading && <Spinner animation="border" variant="light" size="sm" />}
                        </button>
                    </div>
                </div>
            </section>

            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link >
        </>
    )
})
export default Parametres