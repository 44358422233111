import {
    makeAutoObservable, runInAction
} from 'mobx';
import { RootStore } from '.';

export default class AppStore {

    rootStore: RootStore;

    reviews: any = []
    clients: any = []
    events: any = []
    agents: any = []
    prices: any = []
    features: any = []
    options: any = []
    proCategories: any = []

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeAutoObservable(this)
        this.fetchSiteContent()
    }

    fetchSiteContent() {
        this.rootStore.apiStore.post('site').then((response: any) => {
            runInAction(() => {
                this.events = response.events
                this.reviews = response.reviews
                this.agents = response.agents
                this.clients = response.clients
                this.proCategories = response.pro_categories
            })
        })
    }

    setPrices(prices: any){
        this.prices = prices
    }

    setFeatures(features: any){
        this.features = features
    }

    setOptions(options: any){
        this.options = options
    }

    setProCategories(proCategories: any){
        this.proCategories = proCategories
    }

    optionById(optionId: any){
        const options = this.options
        const optionById = options.filter((option: any) => option.id == optionId)[0]
        return optionById
    }

    optionPriceByPriceAndVehicleCategory(optionId: any, vehicleCategory: any){
        const optionById = this.optionById(optionId)
        const optionPrice = optionById.optionPrices.filter((optionPrice: any) => optionPrice.vehicleCategory == vehicleCategory)[0]
        return optionPrice
    }

}
