import React, { useEffect, useState } from 'react'

import { Input, Modal, Select, Steps } from 'antd'
import { useStores } from '../stores'
import toast from 'react-hot-toast'
import { fromNow } from '../utils/dateUtils'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { MAPS_KEY } from '../utils/Constants'
import { shortenAddressLabel, shortenFullAddress } from '../utils/addressUtils'

const AddressCard = ({
    address = null,
    onUpdate,
    selectMode = false,
    selected = false,
    onSelect
}: {
    address?: any,
    onUpdate?: Function,
    selectMode?: boolean,
    selected?: boolean,
    onSelect?: Function
}) => {

    const defaultMapCenter = { lat: -17.587288, lng: -149.5896128 }

    const { TextArea } = Input

    const { apiStore, userStore, geoStore } = useStores()

    const [modalOpen, setModalOpen] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)

    const [label, setLabel] = useState<any>(undefined)
    const [commune, setCommune] = useState<any>(undefined)
    const [fullAddress, setFullAddress] = useState<any>(undefined)
    const [instructions, setInstructions] = useState<any>(undefined)
    const [markerPosition, setMarkerPosition] = useState<any>(defaultMapCenter)
    const [markerHasBeenDragged, setMarkerHasBeenDragged] = useState<any>(false)

    const [citiesOptions, setCitiesOptions] = useState([])

    const [mapCenter, setMapCenter] = useState<any>(defaultMapCenter)

    const _handleClick = () => {
        if(typeof onSelect == 'function'){
            onSelect()
        }
    }

    // Google maps
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: MAPS_KEY
    })

    const updatePosition = (position: any) => {
        setMarkerPosition({
            lat: position.latLng.lat(),
            lng: position.latLng.lng()
        })
        setMarkerHasBeenDragged(true)
    }

    const [map, setMap] = React.useState(null)

    // Stepper
    const [currentStep, setCurrentStep] = useState(0)

    const onChangeCity = (value: string) => {
        setCommune(value)
        let cityObject = geoStore.getCityObjectById(value)
        if (cityObject) {
            let pos = {
                lat: cityObject.lat,
                lng: cityObject.lng
            }
            setMapCenter(pos)
            setMarkerPosition(pos)
        }
    }

    const onSearchCity = (value: string) => {
        console.log('search:', value)
    }

    const communesOptions = () => {
        return citiesOptions.map((item: any) => {
            return {
                value: item.id,
                label: item.name
            }
        })
    }

    useEffect(() => {
        if (modalOpen == false) {
            // Modal has been closed
            setEditMode(false)
            resetAddressInputs()
        } else {
            geoStore.fetchCities().then((resp: any) => {
                setCitiesOptions(resp)
            })
        }
    }, [modalOpen])

    const resetAddressInputs = () => {
        setCurrentStep(0)
        setLabel(undefined)
        setCommune(undefined)
        setFullAddress(undefined)
        setInstructions(undefined)
        setMapCenter(defaultMapCenter)
        setMarkerPosition(defaultMapCenter)
        setMarkerHasBeenDragged(false)
    }

    const deleteAddress = () => {
        setModalDeleteOpen(true)
    }

    const editAddress = () => {
        setEditMode(true)
        setModalOpen(true)
        // Fill inputs
        setLabel(address.label)
        setCommune(address.commune.id)
        setFullAddress(address.fullAddress)
        setInstructions(address.instructions)
        setMapCenter({
            lat: address.lat,
            lng: address.lng
        })
        setMarkerPosition({
            lat: address.lat,
            lng: address.lng
        })
    }

    const handleDeleteAddress = () => {
        setConfirmLoading(true)
        apiStore.post('address/delete/' + address.id, {}).then(addresses => {
            toast.success('Votre adresse a bien été supprimé')
            userStore.setAddresses(addresses)
            setModalDeleteOpen(false)
        }).catch(err => {
            toast.error('Impossible de supprimer votre adresse')
            console.log(err)
        }).finally(() => {
            setConfirmLoading(false)
        })
    }

    const renderNullAddress = () => {
        return (
            <div className="address-add" onClick={() => setModalOpen(true)}>
                <div>
                    <p className='text-center'><i className="fas fa-plus"></i></p>
                    <p>Ajouter une adresse</p>
                </div>
            </div>
        )
    }

    const renderAddress = () => {
        return (
            <div className={"address-card " + (selectMode ? 'address-card-select' : '') + (selected ? ' address-card-selected' : '')} onClick={_handleClick}>
                {selected && <img src="assets/images/icons/vehicle-check.png" className='address-selected-icon' />}
                <div className="address-card__plate">
                    {shortenAddressLabel(address.label)}
                </div>
                <div className="address-card__bottom">
                    <ul className="address-card__list list-unstyled">
                        <li className='address-card__maker position-relative'>
                            <p>{shortenFullAddress(address.fullAddress)}</p>
                            <span className='address-card__category'>{address.commune.name}</span>
                        </li>
                        {!selectMode && <li className='added'>
                            Ajouté {fromNow(address.createdAt)}
                        </li>}
                        {selectMode && <li className={'added' + (selected ? ' text-success' : '')}>
                            {selected ? 'Sélectionné' : 'Cliquez pour sélectionner cette adresse '}
                        </li>}
                    </ul>
                    {!selectMode && <>
                        <p className='edit-address-btn' onClick={() => editAddress()}>
                            Modifier
                        </p>
                        <p className='delete-address-btn' onClick={() => deleteAddress()}>
                            Supprimer
                        </p>
                    </>}
                </div>
            </div>
        )
    }

    const isDisabledSubmit = () => {
        if (currentStep == 0) {
            return !label || !commune || !fullAddress
        } else {
            return !markerHasBeenDragged
        }
    }

    const handleCancel = () => {
        if (currentStep == 0) {
            setModalOpen(false)
        } else {
            setCurrentStep(0)
        }
    }

    const handleOk = async () => {

        // Next STEP
        if (currentStep == 0) {
            setCurrentStep(1)
            return
        }

        // Save address
        setConfirmLoading(true)
        let payload = {
            label,
            commune: { id: commune },
            fullAddress,
            instructions,
            lat: markerPosition.lat,
            lng: markerPosition.lng
        }

        apiStore.post((editMode ? 'address/edit/' + address.id : 'address/add'), payload).then(response => {
            toast.success('Votre adresse a bien été enregistrée')
            resetAddressInputs()
            if (typeof onUpdate == 'function') {
                onUpdate(response)
            }
            setModalOpen(false)
        }).catch(err => {
            toast.error('Impossible d\'enregistrer votre adresse. Veuillez vérifier votre saisie')
            console.log(err)
        }).finally(() => {
            setConfirmLoading(false)
        })
    }

    const onLoad = React.useCallback((map: any) => {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds({
        //     lat: -3.745,
        //     lng: -38.523
        // })
        // map.fitBounds(bounds)
        setMap(map)
    }, [])

    const onUnmount = React.useCallback((map: any) => {
        setMap(null)
    }, [])

    return (
        <>
            {address ?
                renderAddress()
                :
                renderNullAddress()
            }
            {/* MODAL DELETE */}
            <Modal
                open={modalDeleteOpen}
                onOk={handleDeleteAddress}
                confirmLoading={confirmLoading}
                onCancel={() => setModalDeleteOpen(false)}
                okText="Supprimer"
                cancelText="Annuler"
                maskStyle={{ borderRadius: 0 }}
                okButtonProps={{ className: 'ant-btn-danger' }}
            >
                <h5 className="vehicle-modal-title text-danger">Supprimer une adresse</h5>
                <p className='my-4 text-secondary fw-normal'>Souhaitez-vous supprimer cette adresse ?</p>
            </Modal>

            {/* MODAL ADD */}
            <Modal
                open={modalOpen}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                okText={currentStep == 0 ? 'Suivant' : 'Valider'}
                cancelText={currentStep == 0 ? 'Annuler' : 'Précédent'}
                maskStyle={{ borderRadius: 0 }}
                okButtonProps={{ disabled: isDisabledSubmit() }}
                centered={true}
            >
                <h5 className="vehicle-modal-title">{editMode ? 'Modifier' : 'Ajouter'} une adresse</h5>
                <p className='my-4 text-secondary fw-normal'>Renseignez les informations sur <b>l'adresse exacte de stationnement</b>. Ces informations seront stockées de manière sécurisée et ne seront pas partagées.</p>
                {/* <div className="alert alert-warning" role="alert">
                    <b>Tarifs hors zone :</b> Votre adresse est en dehors de Mahina (Ahonu) – Punaauia (Carlton plage)
                </div> */}

                <Steps
                    current={currentStep}
                    items={[{ title: 'Adresse' }, { title: 'Carte' }]}
                    className='ant-address-stepper'
                />

                {/* ADDRESS TEXT INPUT */}
                {currentStep == 0 &&
                    <div className='row'>
                        <div className='col-lg-6'>
                            <p className='mb-1'>Libellé de l'adresse</p>
                            <Input value={label} onChange={e => setLabel(e.target.value)} placeholder={'Ex: Maison / Travail / Plage'} />
                        </div>
                        <div className='col-lg-6'>
                            <p className='mb-1 mt-3 mt-lg-0'>Commune</p>
                            <Select
                                notFoundContent={'Aucune commune trouvée'}
                                value={commune}
                                showSearch
                                placeholder="Séléctionnez une commune"
                                optionFilterProp="children"
                                onChange={onChangeCity}
                                onSearch={onSearchCity}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={communesOptions()}
                            />
                        </div>
                        <div className='col-12 mt-3'>
                            <p className='mb-1 mt-lg-0'>Adresse géographique</p>
                            <Input value={fullAddress} onChange={e => setFullAddress(e.target.value)} placeholder={'Ex: Residence Villa Stencer'} />
                        </div>
                        <div className='col-12 mt-3'>
                            <p className='mb-1 mt-lg-0'>Instructions supplémentaires <small className='text-secondary'>(Facultatif)</small></p>
                            <TextArea rows={3} value={instructions} onChange={e => setInstructions(e.target.value)} placeholder={'Ex: Portail vert, deuxième à droite après le manguier'} />
                        </div>
                    </div>
                }

                {/* ADDRESS MAP MARKER INPUT */}
                {currentStep == 1 &&
                    <div>
                        <div className="alert alert-primary my-2 text-center" role="alert">
                            Déplacez le curseur sur l'adresse exacte de stationnement du véhicule
                        </div>
                        <GoogleMap
                            mapContainerStyle={{
                                width: '100%',
                                height: '350px'
                            }}
                            center={mapCenter}
                            onTilesLoaded={() => setMapCenter(null)}
                            zoom={13}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                        >
                            <Marker
                                draggable={true}
                                onDragEnd={position => updatePosition(position)}
                                position={markerPosition}
                            />
                            <></>
                        </GoogleMap>
                        <p className='fst-italic text-center text-secondary mt-3'>Pour déplacer le marqueur, maintenez le clic dessus et déplacez votre curseur</p>
                    </div>
                }

            </Modal>
        </>
    )

}

export default AddressCard