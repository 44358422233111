import BGPh1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import { useStores } from '../../stores'
import { observer, Observer } from 'mobx-react'
import { Alert, Form, Spinner } from 'react-bootstrap'
import Cleave from "cleave.js/react";
import toast from 'react-hot-toast'
import { Checkbox, Skeleton } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import VehicleCard from '../../components/Vehicle'
import AddressCard from '../../components/Address'
import Carousel from 'react-multi-carousel'
import responsive from '../../utils/carouselResponsive'
import moment from 'moment'
import PricingTable from '../../components/PricingTable'

const OnlineBooking = observer(() => {

    const navigate = useNavigate()
    const carouselRef = useRef<any>()

    const { userStore, apiStore, carStore, appStore } = useStores()

    const fetchCalendarDays = () => {
        apiStore.post('calendar/days/' + selectedPrice.id, { selectedOptions, selectedAddress: selectedAddress?.id }).then((days: any) => {
            setCalendarDays(days)
            // Scroll to first available calendar day
            let firstAvailableDayIndex = 0
            for (let i = 0; i < days.length; i++) {
                const day = days[i];
                if (day.slots.length > 0) {
                    firstAvailableDayIndex = i;
                    break
                }
            }
            if (firstAvailableDayIndex > 3) {
                firstAvailableDayIndex = firstAvailableDayIndex - 1
            }
            console.log(firstAvailableDayIndex)
            // Scroll to first available calendar day
            carouselRef.current.goToSlide(firstAvailableDayIndex)
        })
    }

    useEffect(() => {
        carStore.fetchBrands()
    }, [])

    const [step, setStep] = useState(1)

    const [localVehicles, setLocalVehicles] = useState([])
    const [selectedVehicle, setSelectedVehicle] = useState<any>(null)

    const [localAddresses, setLocalAddresses] = useState([])
    const [selectedAddress, setSelectedAddress] = useState<any>(null)

    const [prices, setPrices] = useState([])
    const [selectedPrice, setSelectedPrice] = useState<any>(null)

    const [selectedOptions, setSelectedOptions] = useState<any>(null)

    const [calendarDays, setCalendarDays] = useState<any>([])
    const [selectedSlot, setSelectedSlot] = useState<any>(null)

    useEffect(() => {
        if (selectedVehicle) {
            setSelectedPrice(null)
            apiStore.post('prestations/' + selectedVehicle.category + '/prices', {}).then((response: any) => {
                setPrices(response)
            }).catch(err => {
                toast.error('Nous n\'avons pas réussi à charger les tarifs correspondant à votre véhicule')
                setPrices([])
            })
        }
        // setSelectedPrice(null)
    }, [selectedVehicle])

    useEffect(() => {
        if (selectedPrice?.id) {
            fetchCalendarDays()
        } else {
            setCalendarDays([])
        }
    }, [selectedPrice, selectedOptions, selectedAddress])

    const goToStep = (newStep: number) => {
        setStep(newStep)
        if (newStep === 3) {
            setSelectedPrice(null)
        }
        setTimeout(() => {
            const section = document.querySelector('#container-step-' + newStep)
            if (section) {
                let position = section.getBoundingClientRect()
                let scrollToY = position.top + window.scrollY
                if (step === 1 || step === 2) {
                    scrollToY -= 300
                } else {
                    scrollToY -= 150
                }
                window.scrollTo(position.left, scrollToY)
            }
        }, 100)
    }

    // Fetch data, vehicles and addresses if user is connected
    useEffect(() => {
        if (userStore.user?.id) {
            setFirstname(userStore.user.firstName)
            setLastname(userStore.user.lastName)
            setEmail(userStore.user.email)
            setPhone(userStore.user.phone)
            userStore.fetchVehicles()
            userStore.fetchAddresses()
        }
    }, [userStore.user?.id])

    // When vehicles in store change, sync it with local state vehicles
    useEffect(() => {
        if (userStore.vehicles?.length) {
            setLocalVehicles(userStore.vehicles)
        }
        if (userStore.addresses?.length) {
            setLocalAddresses(userStore.addresses)
        }
    }, [userStore.vehicles, userStore.addresses])

    // STEP 1 - User data
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')

    const [phone, setPhone] = useState('')
    const [phoneError, setPhoneError] = useState(false)

    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')

    const [createAccountCheckbox, setCreateAccountCheckbox] = useState(false)

    const [promoCode, setPromoCode] = useState('')
    const [activePromo, setActivePromo] = useState<any>(null)

    const isInvalidPassword = () => {
        if (password.length > 3 && password2.length > 3 && password.length < 8 && password2.length < 8) {
            return true
        }
        if (password.length >= 8 && password2.length > 4 && password !== password2) {
            return true
        }
        return false
    }

    const isDisabledSubmitStep1 = () => {
        let conditions = email?.length < 4 || firstname?.length < 2 || lastname?.length < 2 || phone?.length !== 8
        if (createAccountCheckbox) {
            return conditions || password.length < 7 || password !== password2
        }
        return conditions;
    }

    const toastError = (message: string) => {
        toast.error(message, { duration: 2000 })
    }

    const submitStep1 = () => {
        if (createAccountCheckbox && !userStore.user) {
            if (password !== password2) {
                toastError('Vos mots de passe ne correspondent pas')
                return
            }
            if (password.length < 8 || password2.length < 8) {
                toastError('Votre mot de passe doit faire 8 caractères minimum')
                return
            }
            let payload = {
                firstname,
                lastname,
                email,
                phone,
                password
            }
            apiStore.post('register', payload).then((user: any) => {
                toast.success('Votre compte a bien été créé !', { duration: 2000 })
                userStore.setUser(user)
                goToStep(2)
            }).catch(err => {
                if (err.error === true) {
                    toastError('Inscription impossible, cette adresse email est déjà utilisée')
                } else {
                    toastError('Inscription impossible, une erreur inconnue est survenue')
                }
            })
        } else {
            goToStep(2)
        }
    }

    useEffect(() => {
        setSelectedOptions([])
        if (selectedPrice) {
            setTimeout(() => {
                const section = document.querySelector('#pricing-table-options-container')
                if (section) {
                    let position = section.getBoundingClientRect()
                    let scrollToY = position.top + window.scrollY
                    if (step === 1 || step === 2) {
                        scrollToY -= 300
                    } else {
                        scrollToY -= 150
                    }
                    window.scrollTo(position.left, scrollToY)
                }
            }, 100)
        }
    }, [selectedPrice])

    const submitStep2 = () => {
        goToStep(3)
    }

    const submitStep3 = (options: any) => {
        setSelectedOptions(options)
        goToStep(4)
    }

    const submitStep4 = () => {
        goToStep(5)
    }

    const submitStep5 = () => {
        window.scrollTo(0, 0)
        goToStep(6)
    }

    const _addVehicle = async (vehicle: any) => {
        userStore.addVehicle(vehicle)
        setSelectedVehicle(vehicle)
    }

    const _mergeAddresses = (addresses: any) => {
        if (userStore.user?.id) {
            userStore.setAddresses(addresses)
        } else {
            userStore.mergeAddresses(addresses)
        }
        setLocalAddresses(addresses)
        if (userStore.addresses?.length === 1) {
            setSelectedAddress(userStore.addresses[0])
        }
    }

    const getExtraFeeDay = (price: number) => {
        return price * 0.8
    }

    const getCommandeReduction = () => {
        if (activePromo) {
            return Math.round(getCommandeTotal() * activePromo.amount / 100)
        } else {
            return 0
        }
    }

    const getCommandeTotal = () => {
        let total = selectedPrice.price
        total += selectedAddress?.commune?.extraFeeAmount
        if (selectedSlot.isExtra) {
            total += getExtraFeeDay(selectedPrice.price)
        }
        // Add option prices
        for (let i = 0; i < selectedOptions.length; i++) {
            const optionId = selectedOptions[i];
            const optionPrice = appStore.optionPriceByPriceAndVehicleCategory(optionId, selectedVehicle.category).price
            total += optionPrice
        }
        return total
    }

    const submitCodePromo = () => {
        apiStore.post('booking/check-code', { code: promoCode }).then((resp: any) => {
            toast.success('Vous bénéficiez d\'une réduction de ' + resp.amount + '% sur votre réservation')
            setActivePromo(resp)
        }).catch(err => {
            toast.error('Le code que vous avez saisi n\'est plus valide')
        })
    }

    const submitBooking = () => {
        let payload = {
            contactEmail: email,
            contactFirstName: firstname,
            contactLastName: lastname,
            contactPhone: phone,
            address: { id: selectedAddress.id },
            vehicle: { id: selectedVehicle.id },
            price: { id: selectedPrice.id },
            selectedOptions: selectedOptions ?? [],
            slotObj: selectedSlot,
            activePromo
        }

        apiStore.post('booking/add', payload).then((resp: any) => {
            console.log(resp)
            if (resp.paymentToken) {
                window.location.href = '/reservation/' + resp.paymentToken
            }
        }).catch(err => {
            if (err.error_type === 'not_available') {
                toast.error('Le créneau que vous avez sélectionné n\'est plus disponible. Veuillez en sélectionner un autre')
            } else {
                toast.error('Une erreur est survenue, impossible de traiter votre demande')
            }
        })
    }

    return (
        <>
            <div className="page-wrapper">

                <div className="stricky-header stricked-menu main-menu">
                    <div className="sticky-header__content"></div>
                </div>
                <section className="page-header">
                    <div className="page-header__bg" style={{ backgroundImage: `url(${BGPh1})` }}></div>

                    <div className="container">
                        <h2>Votre rendez-vous</h2>

                        <ul className="thm-breadcrumb list-unstyled">
                            <li><Link to="/">Accueil</Link></li>
                            <li>/</li>
                            <li><span>Prendre rendez-vous</span></li>
                        </ul>
                    </div>
                </section>

                <section className="online-booking">

                    {/* FORM PART */}
                    {step < 6 && <>
                        {/* STEP 1 */}
                        <div className="container" id="container-step-1">
                            <div className="online-booking__top">
                                <div className="online-booking__count">1</div>
                                <div className="section-title section-title-booking">
                                    <span className="section-title__tagline">Étape 1</span>
                                    <h2 className="section-title__title">Vos coordonnées</h2>
                                    <p className='mt-4 mb-0'>Afin de garantir la meilleure prise en charge possible de votre réservation, merci de nous laisser vos coordonnées afin de vous contacter en cas de besoin.</p>
                                    {!userStore.user && <Link to={'/connexion?redirect=booking'} className='mt-2 mb-0 link-btn'>Vous avez déjà un compte ? Connectez-vous !</Link>}
                                </div>
                            </div>

                            {/* User is connected  */}
                            {userStore.user &&
                                <div className="alert alert-primary" role="alert">
                                    <div className='row'>
                                        <div className='col-10'>
                                            <div className='d-flex'>
                                                <img src="assets/images/icons/check.png" alt="Connecté" className="img-fluid connected-icon my-auto" />
                                                <p className='my-0 ms-4'>Connecté en tant que <b>{userStore.user.firstName} {userStore.user.lastName}</b></p>
                                            </div>
                                        </div>
                                        {/* <div className='col-2'>
                                            <p className='text-danger text-decoration-underline m-0 cursor-pointer'>Se déconnecter</p>
                                        </div> */}
                                    </div>
                                </div>
                            }

                            <div className="row dct-fields">
                                <div className='col-lg-6'>
                                    <Form.Group>
                                        <Form.Label className='dct-label  mt-3'>Prénom</Form.Label>
                                        <Form.Control value={firstname} onChange={e => setFirstname(e.target.value)} type="text" placeholder="Votre prénom" />
                                    </Form.Group>
                                </div>
                                <div className='col-lg-6'>
                                    <Form.Group>
                                        <Form.Label className='dct-label mt-3'>Nom</Form.Label>
                                        <Form.Control value={lastname} onChange={e => setLastname(e.target.value)} type="text" placeholder="Votre nom" />
                                    </Form.Group>
                                </div>
                                <div className='col-lg-6'>
                                    <Form.Group>
                                        <Form.Label className='dct-label  mt-3'>Email</Form.Label>
                                        <Form.Control value={email} onChange={e => setEmail(e.target.value)} type="email" placeholder="Votre adresse email" />
                                    </Form.Group>
                                </div>
                                <div className='col-lg-6'>
                                    <Form.Group>
                                        <Form.Label className='dct-label mt-3'>N° de téléphone</Form.Label>
                                        {/* <Form.Control onChange={e => setPhone(e.target.value)} type="number" placeholder="89 XX XX XX" /> */}
                                        <Cleave
                                            value={phone}
                                            placeholder="89 XX XX XX"
                                            options={{
                                                blocks: [2, 2, 2, 2],
                                                delimiter: " ",
                                                numericOnly: true
                                            }}
                                            onChange={e => setPhone(e.target.rawValue)}
                                            className="form-control"
                                        />
                                        {phoneError && <span className='helper-text__error'>Numéro de téléphone incorrect</span>}
                                    </Form.Group>
                                </div>
                                {createAccountCheckbox && !userStore.user &&
                                    <>
                                        <div className='col-lg-6'>
                                            <Form.Group>
                                                <Form.Label className='dct-label mt-3'>Mot de passe</Form.Label>
                                                <Form.Control onChange={e => setPassword(e.target.value)} className={isInvalidPassword() ? 'is-invalid' : ''} type="password" placeholder="Votre mot de passe" />
                                                <span className='helper-text'>Minimum 8 caractères</span>
                                            </Form.Group>
                                        </div>
                                        <div className='col-lg-6'>
                                            <Form.Group>
                                                <Form.Label className='dct-label mt-3'>Confirmation Mot de passe</Form.Label>
                                                <Form.Control onChange={e => setPassword2(e.target.value)} className={isInvalidPassword() ? 'is-invalid' : ''} type="password" placeholder="Confirmez votre mot de passe" />
                                                {isInvalidPassword() && <span className='helper-text__error'>Les mots de passe doivent correspondre</span>}
                                            </Form.Group>
                                        </div>
                                    </>
                                }
                                {!userStore.user && <div className='d-flex mt-4 justify-content-center'>
                                    <Checkbox checked={createAccountCheckbox} onChange={(e: CheckboxChangeEvent) => setCreateAccountCheckbox(e.target.checked)} className='my-auto' />
                                    <p className='my-auto ms-3 cursor-pointer' onClick={() => setCreateAccountCheckbox(!createAccountCheckbox)}>Créer un compte et sauvegarder mes données</p>
                                </div>}
                                {step === 1 && <div className="d-flex justify-content-end mt-5">
                                    <button disabled={isDisabledSubmitStep1()} onClick={submitStep1} className="thm-btn mt-3">
                                        {!apiStore.isLoading && <span>Continuer<i className="far fa-arrow-alt-circle-right"></i></span>}
                                        {apiStore.isLoading && <Spinner animation="border" variant="light" size="sm" />}
                                    </button>
                                </div>}
                            </div>
                        </div>

                        {/* STEP 2 */}
                        <div className={"container " + (step < 2 ? 'disabled' : '')} id="container-step-2">
                            <div className="online-booking__top">
                                <div className="online-booking__count">2</div>
                                <div className="section-title">
                                    <span className="section-title__tagline">Étape 2</span>
                                    <h2 className="section-title__title">Sélection du véhicule</h2>
                                    <p className='mt-4 mb-0'>Sélectionnez le véhicule à prendre en charge pour cette réservation en cliquant dessus. Vous pouvez gérer vos véhicule depuis votre espace client.</p>
                                </div>
                            </div>

                            <div className='row'>
                                {localVehicles.map((vehicle: any) => (
                                    <div key={vehicle.id} className='col-md-6 col-lg-4'>
                                        <VehicleCard selectMode={true} onSelect={() => setSelectedVehicle(vehicle)} selected={vehicle.id === selectedVehicle?.id} vehicle={vehicle} />
                                    </div>
                                ))}
                                <div className='col-md-6 col-lg-4'>
                                    <VehicleCard selectMode={true} vehicle={null} onAdd={(vehicle: any) => _addVehicle(vehicle)} />
                                </div>
                            </div>

                            {step === 2 && <div className="d-flex justify-content-end mt-5 dct-fields">
                                <button disabled={!selectedVehicle} onClick={submitStep2} className="thm-btn mt-3">
                                    Continuer<i className="far fa-arrow-alt-circle-right"></i>
                                </button>
                            </div>}

                        </div>

                        {/* STEP 3 */}
                        <div className={"container " + (step < 3 ? 'disabled' : '')} id="container-step-3">
                            <div className="online-booking__top">
                                <div className="online-booking__count">3</div>
                                <div className="section-title">
                                    <span className="section-title__tagline">Étape 3</span>
                                    <h2 className="section-title__title">Prestations</h2>
                                    <p className='mt-4 mb-0'>Veuillez sélectionner le forfait que vous souhaitez réserver. {selectedVehicle && ('Les prix affichés sont ceux correspondant à votre type de véhicule : ' + carStore.categoryByKey(selectedVehicle.category).name)}</p>
                                </div>
                            </div>

                            {!apiStore.isLoading && prices.length === 0 && <div className='w-100'>
                                <Skeleton.Node className='w-100'>
                                    <p></p>
                                </Skeleton.Node>
                            </div>}

                            {/* {apiStore.isLoading && <div className='d-flex'><Spinner className='m-auto' animation="border" /></div>} */}

                            {selectedVehicle && !apiStore.isLoading && prices.length === 0 && <Alert variant={'warning'}>
                                Malheureusement, nous n'avons aucune prestation disponible pour votre catégorie de véhicule
                            </Alert>}

                            <div className='row' style={{ marginTop: 15 }}>
                                <PricingTable
                                    selectedTabMenu={selectedVehicle?.category}
                                    hideTabMenu={true}
                                    selectedPrice={selectedPrice}
                                    onSelectPrice={(price: any) => setSelectedPrice(price)}
                                    canSelectOptions={true}
                                    selectedOptions={selectedOptions}
                                    onSelectOptions={(options: any) => submitStep3(options)}
                                />
                                {/* {prices.map((price: any) => (
                                    <div key={price.id} className="col-12 col-md-6 col-lg-4">
                                        <div className="price-card">
                                            <div>
                                                <p className="price-card__name text-info fw-bold text-uppercase">{price.prestation.name}</p>
                                                <p className="price-card__amount">
                                                    {(price.price).toLocaleString()}
                                                    <span className="price-card__amount-fraction">XPF</span>
                                                </p>
                                                <div className="price-card__duration">
                                                    <i className="far fa-clock"></i>
                                                    {price.time}
                                                </div>
                                            </div>
                                            <div className="price-card__bottom">
                                                <ul className="price-card__list list-unstyled">
                                                    {price.prestation?.descriptionLines && Object.entries(price.prestation.descriptionLines).map((arr: any, idx) => {
                                                        return (
                                                            <li key={idx}>
                                                                <i className="fa fa-check"></i>
                                                                {arr[1]}
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                <button onClick={() => submitStep3(price)} className={"thm-btn " + (selectedPrice?.id === price.id ? 'filled' : '')}>
                                                    {selectedPrice?.id !== price.id ? 'Sélectionner' : 'Sélectionné'}
                                                    {selectedPrice?.id !== price.id && <i className="far fa-arrow-alt-circle-right"></i>}
                                                    {selectedPrice?.id === price.id && <i className="fas fa-check"></i>}
                                                </button >
                                            </div>
                                        </div>
                                    </div>
                                ))} */}
                            </div>
                        </div>

                        {/* STEP 4 */}
                        <div className={"container " + (step < 4 ? 'disabled' : '')} id="container-step-4">
                            <div className="online-booking__top">
                                <div className="online-booking__count">4</div>
                                <div className="section-title">
                                    <span className="section-title__tagline">Étape 4</span>
                                    <h2 className="section-title__title">Sélection de l'adresse</h2>
                                    <p className='mt-4 mb-0'>Sélectionnez le lieu où nous pourrons procéder au lavage de votre véhicule. Vous pouvez gérer vos adresses depuis votre espace client.</p>
                                </div>
                            </div>

                            <div className='row'>
                                {localAddresses.map((address: any) => (
                                    <div key={address.id} className='col-md-6 col-lg-4'>
                                        <AddressCard selectMode={true} onSelect={() => setSelectedAddress(address)} selected={address.id === selectedAddress?.id} address={address} />
                                    </div>
                                ))}
                                <div className='col-md-6 col-lg-4'>
                                    <AddressCard selectMode={true} address={null} onUpdate={(addresses: any) => _mergeAddresses(addresses)} />
                                </div>
                            </div>

                            {selectedAddress && selectedAddress.commune.extraFeeAmount > 0 && <>
                                <div className="online-booking__extra mt-4">
                                    <div className="online-booking__extra-item">
                                        <h3 className="online-booking__extra-title"><Link to="/servicedetails">Tarif hors zone</Link></h3>
                                        <ul className="list-unstyled online-booking__extra-list">
                                            <li>
                                                Votre commune est soumise à des frais supplémentaires
                                            </li>
                                            <li>
                                                <button className="thm-btn filled">
                                                    {selectedAddress.commune.extraFeeAmount.toLocaleString()} FCFP
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </>}

                            {step === 4 && <div className="d-flex justify-content-end mt-5 dct-fields">
                                <button disabled={!selectedAddress} onClick={submitStep4} className="thm-btn mt-3">
                                    Continuer<i className="far fa-arrow-alt-circle-right"></i>
                                </button>
                            </div>}

                        </div>

                        {/* STEP 5 */}
                        <div className={"container " + (step < 5 ? 'disabled' : '')} id="container-step-5">
                            <div className="online-booking__top">
                                <div className="online-booking__count">5</div>
                                <div className="section-title">
                                    <span className="section-title__tagline">Étape 5</span>
                                    <h2 className="section-title__title">Sélection du créneau</h2>
                                    <p className='mt-4 mb-0'>Veuillez sélectionner un créneau pour votre véhicule. Veuillez noter qu'un surplus de 80% est appliqué pour les jours fériés ou le dimanche.</p>
                                </div>
                            </div>

                            <div style={{ minHeight: 200 }}>
                                <Carousel
                                    ref={carouselRef}
                                    responsive={responsive}
                                >
                                    {calendarDays.map((day: any) => {
                                        return (
                                            <div key={day.date} className='calendar-cell'>
                                                <div className={'day-indicator ' + (day.isWeekEnd && 'is-week-end')}>
                                                    {day.displayDate}
                                                </div>
                                                <div className='creneau-container'>
                                                    {day?.slots?.map((slot: any) => {
                                                        return (
                                                            <div key={slot.hash} className={'creneau ' + (selectedSlot?.hash === slot.hash ? 'filled ' : '')} onClick={() => setSelectedSlot(slot)}>
                                                                {slot.virtualStart} - {slot.virtualEnd}
                                                            </div>
                                                        )
                                                    })}
                                                    {day?.slots?.length === 0 && <>
                                                        <p className='text-center my-3'>Aucun créneau disponible restant</p>
                                                    </>}
                                                    {/* <div className={'creneau creneau-morning ' + (morning_available ? '' : 'disabled ') + (selectedSlot?.hash === day.slots[0].hash ? 'filled ' : '')} onClick={() => setSelectedSlot(day.slots[0])}>
                                                            {morning_available ? '8h - 12h' : 'INDISPONIBLE'}
                                                        </div>
                                                        <div className={'creneau creneau-afternoon ' + (afternoon_available ? '' : 'disabled') + (selectedSlot?.hash === day.slots[1].hash ? 'filled ' : '')} onClick={() => setSelectedSlot(day.slots[1])}>
                                                            {afternoon_available ? '13h - 16h' : 'INDISPONIBLE'}
                                                        </div> */}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            </div>

                            {selectedSlot?.isExtra && selectedPrice && <>
                                <div className="online-booking__extra mt-4">
                                    <div className="online-booking__extra-item">
                                        <h3 className="online-booking__extra-title"><Link to="/servicedetails">Tarif dimanche / jour férié</Link></h3>
                                        <ul className="list-unstyled online-booking__extra-list">
                                            <li>
                                                80% du montant de la prestation
                                            </li>
                                            <li>
                                                <button className="thm-btn filled">
                                                    {getExtraFeeDay(selectedPrice.price).toLocaleString(

                                                    )} FCFP
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </>}

                            {step === 5 && <div className="d-flex justify-content-end mt-5 dct-fields">
                                <button disabled={!selectedSlot} onClick={submitStep5} className="thm-btn mt-3">
                                    Continuer<i className="far fa-arrow-alt-circle-right"></i>
                                </button>
                            </div>}
                        </div>
                    </>}

                    {/* CONFIRMATION PART */}
                    {step >= 6 && <>
                        {/* STEP 6 */}
                        <div className="container" id='container-step-6'>
                            <div className="online-booking__top">
                                <div className="online-booking__count">6</div>
                                <div className="section-title">
                                    <span className="section-title__tagline">Étape 6</span>
                                    <h2 className="section-title__title">Vérifiez vos informations</h2>
                                    <p className='mt-4 mb-4'>Vous êtes sur le point de valider votre réservation. Veuillez vérifier tous les informations avant de continuer.</p>
                                </div>
                            </div>

                            <div className="online-booking__extra">
                                <div className="online-booking__extra-item">
                                    <div className='plate-card'>
                                        Vos informations
                                    </div>
                                    <div>
                                        <h3 className="online-booking__extra-title mb-ncard">
                                            {firstname} {lastname}
                                        </h3>
                                    </div>
                                    <ul className="list-unstyled online-booking__extra-list">
                                        <li>
                                            <i className="fas fa-phone"></i>
                                            {phone}
                                        </li>
                                        <li>
                                            <i className="fas fa-envelope"></i>
                                            {email}
                                        </li>
                                        <li>
                                            <button className="thm-btn" onClick={() => goToStep(1)}>
                                                Modifier
                                                <i className="fa fa-arrow-alt-circle-right"></i>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="online-booking__extra mt-3">
                                <div className="online-booking__extra-item">
                                    <div className='plate-card'>
                                        Votre véhicule
                                    </div>
                                    <div>
                                        <h3 className="online-booking__extra-title mb-ncard">
                                            {selectedVehicle.brand} {selectedVehicle.model}
                                        </h3>
                                    </div>
                                    <ul className="list-unstyled online-booking__extra-list">
                                        <li>
                                            <i className="fas fa-receipt"></i>
                                            {selectedVehicle.plate}
                                        </li>
                                        <li>
                                            <i className="fas fa-car"></i>
                                            {carStore.categoryByKey(selectedVehicle.category).name}
                                        </li>
                                        <li>
                                            <button className="thm-btn" onClick={() => goToStep(2)}>
                                                Modifier
                                                <i className="fa fa-arrow-alt-circle-right"></i>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="online-booking__extra mt-3">
                                <div className="online-booking__extra-item">
                                    <div className='plate-card'>
                                        Prestation sélectionnée
                                    </div>
                                    <div>
                                        <h3 className="online-booking__extra-title text-info mb-ncard">
                                            {selectedPrice.prestation.name}
                                        </h3>
                                    </div>
                                    <ul className="list-unstyled online-booking__extra-list">
                                        <li>
                                            <i className="far fa-clock"></i>
                                            {selectedPrice.time}
                                        </li>
                                        <li>
                                            <i className="fas fa-tag"></i>
                                            {selectedPrice.price.toLocaleString()} FCFP
                                        </li>
                                        <li>
                                            <button className="thm-btn" onClick={() => goToStep(3)}>
                                                Modifier
                                                <i className="fa fa-arrow-alt-circle-right"></i>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="online-booking__extra mt-3">
                                <div className="online-booking__extra-item">
                                    <div className='plate-card'>
                                        Date et lieu
                                    </div>
                                    <div>
                                        <h3 className="online-booking__extra-title mb-ncard">
                                            {selectedSlot.displayDate}
                                        </h3>
                                    </div>
                                    <ul className="list-unstyled online-booking__extra-list">
                                        <li>
                                            <i className="fas fa-map-marker"></i>
                                            {selectedAddress.commune.name}, {selectedAddress.fullAddress}
                                        </li>
                                        <li>
                                            <i className="far fa-clock"></i>
                                            {selectedSlot.virtualStart} - {selectedSlot.virtualEnd}
                                        </li>
                                        <li>
                                            <button className="thm-btn" onClick={() => goToStep(5)}>
                                                Modifier
                                                <i className="fa fa-arrow-alt-circle-right"></i>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <hr className='secondary-hr my-5' />

                            <div className="online-booking__top">
                                <div className="online-booking__count invisible"></div>
                                <div className="section-title">
                                    <h2 className="section-title__title">Total</h2>
                                    <p className='mt-4 mb-0'>Veuillez trouver ci-dessous le détail du total à régler pour votre réservation.</p>
                                </div>
                            </div>

                            <div className="online-booking__extra">
                                {/* FORMULE */}
                                <div className="online-booking__extra-item">
                                    <h3 className="online-booking__extra-title text-secondary fw-normal">
                                        Formule <b className='text-info'>{selectedPrice.prestation.name}</b>
                                    </h3>
                                    <ul className="list-unstyled online-booking__extra-list">
                                        <li>
                                            <button className="thm-btn filled">
                                                {selectedPrice.price.toLocaleString()} FCFP
                                            </button>
                                        </li>
                                    </ul>
                                </div>

                                {/* OPTIONS */}
                                {selectedOptions.map((selectedOptionId: any) => {
                                    return <div className="online-booking__extra-item">
                                        <h3 className="online-booking__extra-title text-secondary fw-normal">
                                            Option <b className='text-info'>{appStore.optionById(selectedOptionId).name}</b>
                                        </h3>
                                        <ul className="list-unstyled online-booking__extra-list">
                                            <li>
                                                <button className="thm-btn filled">
                                                    {appStore.optionPriceByPriceAndVehicleCategory(selectedOptionId, selectedVehicle.category).price} FCFP
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                })}

                                {/* TARIF HORS ZONE */}
                                {selectedAddress.commune.extraFeeAmount > 0 && <div className="online-booking__extra-item">
                                    <h3 className="online-booking__extra-title text-secondary fw-normal">
                                        Tarif hors zone
                                    </h3>
                                    <ul className="list-unstyled online-booking__extra-list">
                                        <li>
                                            <button className="thm-btn filled">
                                                {selectedAddress.commune.extraFeeAmount.toLocaleString()} FCFP
                                            </button>
                                        </li>
                                    </ul>
                                </div>}

                                {/* TARIF DIMANCHE / JOUR FERIE */}
                                {selectedSlot.isExtra && <div className="online-booking__extra-item">
                                    <h3 className="online-booking__extra-title text-secondary fw-normal">
                                        Tarif dimanche / jour férié
                                    </h3>
                                    <ul className="list-unstyled online-booking__extra-list">
                                        <li>
                                            Surplus de 80% pour les dimanche et jours fériés
                                        </li>
                                        <li>
                                            <button className="thm-btn filled">
                                                {getExtraFeeDay(selectedPrice.price).toLocaleString()} FCFP
                                            </button>
                                        </li>
                                    </ul>
                                </div>}

                                {/* PROMO CODE */}
                                {activePromo && <div className="online-booking__extra-item">
                                    <h3 className="online-booking__extra-title text-secondary fw-normal">
                                        Code promo {activePromo.name}
                                    </h3>
                                    <ul className="list-unstyled online-booking__extra-list">
                                        <li>
                                            <b>-{activePromo.amount}%</b>
                                        </li>
                                        <li>
                                            <button className="thm-btn filled">
                                                -{getCommandeReduction().toLocaleString()} FCFP
                                            </button>
                                        </li>
                                    </ul>
                                </div>}

                                {/* TOTAL */}
                                <div className="online-booking__extra-item">
                                    <h3 className="online-booking__extra-title">
                                        TOTAL
                                    </h3>
                                    <ul className="list-unstyled online-booking__extra-list">
                                        <li>
                                            Total incl. TVA (13%)
                                        </li>
                                        <li>
                                            <button className="thm-btn total-btn">
                                                {(getCommandeTotal() - getCommandeReduction()).toLocaleString()} FCFP
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="contact-form-validated contact-one__form mt-5">
                                {userStore.user?.id && <div className='row'>
                                    <div className='col-md-6 col-12'>

                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <Form.Group className='code-reduc-wrapper'>
                                            <Form.Label className='dct-label  mt-3'>Code de réduction</Form.Label>
                                            <div className='input-wrapper'>
                                                <Form.Control readOnly={activePromo} value={promoCode} onChange={e => setPromoCode(e.target.value)} type="text" placeholder="Entrez votre code de réduction" />
                                                {!activePromo && <button disabled={apiStore.isLoading || promoCode.length < 3} onClick={submitCodePromo} className="thm-btn submit-code-btn">
                                                    Valider
                                                </button>}
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>}
                                <div className="row">
                                    {/* <div className="col-md-12">
                                            <Form.Label className='dct-label'>INFORMATIONS À NOUS TRANSMETTRE</Form.Label>
                                            <textarea name="message" placeholder="Ce champ est facultatif. Vous pouvez y inscrire toute information pouvant nous être utile concernant votre réservation."></textarea>
                                        </div> */}
                                    <div className="col-md-12">
                                        <button disabled={apiStore.isLoading} onClick={submitBooking} className="thm-btn float-end mobile-full-width confirm-booking-btn">
                                            {!apiStore.isLoading && <span>Confirmer ma réservation<i className="far fa-arrow-alt-circle-right"></i></span>}
                                            {apiStore.isLoading && <Spinner animation="border" variant="light" size="sm" />}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}

                    {/* <div className="container">
                            <div className="online-booking__top">
                                <div className="online-booking__count"></div>
                                <div className="section-title">
                                    <span className="section-title__tagline">Select Them Now</span>
                                    <h2 className="section-title__title">Choose Extra Features</h2>
                                </div>
                            </div>

                            <div className="online-booking__extra">
                                <div className="online-booking__extra-item">
                                    <h3 className="online-booking__extra-title"><Link to="/servicedetails">Tire Shine</Link></h3>
                                    <ul className="list-unstyled online-booking__extra-list">
                                        <li>
                                            <i className="far fa-clock"></i>
                                            5 Minutes
                                        </li>
                                        <li>
                                            <i className="fa fa-tags"></i>
                                            $3.99
                                        </li>
                                        <li>
                                            <Link to="/onlinebooking" className="thm-btn">
                                                Select Now
                                                <i className="fa fa-arrow-alt-circle-right"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="online-booking__extra-item">
                                    <h3 className="online-booking__extra-title"><Link to="/servicedetails">Express Interior</Link></h3>
                                    <ul className="list-unstyled online-booking__extra-list">
                                        <li>
                                            <i className="far fa-clock"></i>
                                            5 Minutes
                                        </li>
                                        <li>
                                            <i className="fa fa-tags"></i>
                                            $3.99
                                        </li>
                                        <li>
                                            <Link to="/onlinebooking" className="thm-btn">
                                                Select Now
                                                <i className="fa fa-arrow-alt-circle-right"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="online-booking__extra-item">
                                    <h3 className="online-booking__extra-title"><Link to="/servicedetails">Interior Vacuum</Link></h3>
                                    <ul className="list-unstyled online-booking__extra-list">
                                        <li>
                                            <i className="far fa-clock"></i>
                                            5 Minutes
                                        </li>
                                        <li>
                                            <i className="fa fa-tags"></i>
                                            $3.99
                                        </li>
                                        <li>
                                            <Link to="/onlinebooking" className="thm-btn">
                                                Select Now
                                                <i className="fa fa-arrow-alt-circle-right"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="online-booking__extra-item">
                                    <h3 className="online-booking__extra-title"><Link to="/servicedetails">Dashboard Polish & Clean</Link></h3>
                                    <ul className="list-unstyled online-booking__extra-list">
                                        <li>
                                            <i className="far fa-clock"></i>
                                            5 Minutes
                                        </li>
                                        <li>
                                            <i className="fa fa-tags"></i>
                                            $3.99
                                        </li>
                                        <li>
                                            <Link to="/onlinebooking" className="thm-btn">
                                                Select Now
                                                <i className="fa fa-arrow-alt-circle-right"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="online-booking__extra-item">
                                    <h3 className="online-booking__extra-title"><Link to="/servicedetails">Engine Wash</Link></h3>
                                    <ul className="list-unstyled online-booking__extra-list">
                                        <li>
                                            <i className="far fa-clock"></i>
                                            5 Minutes
                                        </li>
                                        <li>
                                            <i className="fa fa-tags"></i>
                                            $3.99
                                        </li>
                                        <li>
                                            <Link to="/onlinebooking" className="thm-btn">
                                                Select Now
                                                <i className="fa fa-arrow-alt-circle-right"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="online-booking__extra-item">
                                    <h3 className="online-booking__extra-title"><Link to="/servicedetails">Seat Wash & Clean</Link></h3>
                                    <ul className="list-unstyled online-booking__extra-list">
                                        <li>
                                            <i className="far fa-clock"></i>
                                            5 Minutes
                                        </li>
                                        <li>
                                            <i className="fa fa-tags"></i>
                                            $3.99
                                        </li>
                                        <li>
                                            <Link to="/onlinebooking" className="thm-btn">
                                                Select Now
                                                <i className="fa fa-arrow-alt-circle-right"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div> */}

                </section>

            </div>

            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        </>
    )
})

export default OnlineBooking