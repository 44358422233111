import BGPh1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useStores } from '../../stores'

import $ from 'jquery';
import PricingTable from '../../components/PricingTable';

const Pricing = () => {

    const { apiStore } = useStores()

    return (
        <>
            <div className="page-wrapper">

                <div className="stricky-header stricked-menu main-menu">
                    <div className="sticky-header__content"></div>
                </div>
                <section className="page-header">
                    <div className="page-header__bg" style={{ backgroundImage: `url(${BGPh1})` }}></div>

                    <div className="container">
                        <h2>Tarifs</h2>
                        <ul className="thm-breadcrumb list-unstyled">
                            <li><Link to="/">Accueil</Link></li>
                            <li>/</li>
                            <li><span>Nos forfaits</span></li>
                        </ul>
                    </div>
                </section>

                <div className="pricing-one">
                    <div className="container">
                        <PricingTable canSelectOptions={false} />
                    </div>
                </div>
                <section className="call-to-action">
                    <div className="container">
                        <h3 className="call-to-action__title">L'inscription prend moins de 2 minutes</h3>

                        <div className="call-to-action__btn-wrap">
                            <Link to="/prendre-rendez-vous" className="thm-btn white">
                                Réservez maintenant
                                <i className="far fa-arrow-alt-circle-right"></i>
                            </Link>
                        </div>
                    </div>
                </section>


            </div>

            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>

        </>
    )
}

export default Pricing
