import axios from 'axios';
import { API_URL, BASE_URL, MAPS_TOKEN } from '../utils/settings';

class ApiService {

    public post(action: string, user: any, params: any) {

        let configurationObject: any = {
            method: 'post',
            url: BASE_URL + API_URL + action,
            data: JSON.stringify(params),
            headers: { 'content-type' : 'application/json'}
        };

        if (user && user.token) {
            let token = "Bearer " + user.token;
            configurationObject.headers.Authorization = token;
        }

        return new Promise(async (resolve, reject) => {
            axios(configurationObject).then(response => {
                if ((typeof response.data == 'object' || response.data == null) && !response?.data?.error){
                    resolve(response.data);
                }else{
                    reject(response.data);
                }
            }).catch(error => {
                reject(error);
            })
        });
    }

    public geocode(text: string){
        return new Promise(async (resolve, reject) => {
            axios({ method: 'get', url: "https://maps.googleapis.com/maps/api/geocode/json?address=" + text + "&key=" + MAPS_TOKEN }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            })
        });
    }

    public places_suggestions(text: string): any {
        return new Promise(async (resolve, reject) => {
            axios({ method: 'get', url: "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=" + text + "&types=geocode&language=fr&key=" + MAPS_TOKEN }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            })
        });
    }
}

export const apiService = new ApiService()