import { BrowserRouter as Router } from "react-router-dom"
import './assets/vendors/bootstrap/css/bootstrap.min.css'
import './assets/vendors/animate/animate.min.css'
import './assets/vendors/fontawesome/css/all.min.css'
import './assets/vendors/jarallax/jarallax.css'
import './assets/vendors/jquery-magnific-popup/jquery.magnific-popup.css'
import './assets/vendors/nouislider/nouislider.min.css'
import './assets/vendors/nouislider/nouislider.pips.css'
import './assets/vendors/odometer/odometer.min.css'
import './assets/vendors/swiper/swiper.min.css'
import './assets/vendors/crsine-icons/style.css'
import './assets/vendors/tiny-slider/tiny-slider.min.css'
import './assets/css/crsine.css'
import "react-multi-carousel/lib/styles.css";
import React from "react";
import Routing from "./routes"
import { Toaster } from "react-hot-toast"
import { Provider } from 'mobx-react'
import { rootStore } from "./stores"
import { ConfigProvider } from "antd"

export const App = () => {
  return (
    <Router>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#02489d'
          },
        }}
      >
        <Provider {...rootStore.getStores()}>
          <Routing />
        </Provider>
      </ConfigProvider>
      <Toaster />
    </Router>
  );
}

export default App
