import { API_URL, BASE_URL } from "./settings";

export const placeholderBrandByType = (type: string|null) => {
    if(type == 'auto'){
        return 'Ex : Ford'
    }else if(type == 'moto'){
        return 'Ex : Honda'
    }else if(type == 'bateau'){
        return 'Ex : Falcon'
    }else{
        return 'Marque du véhicule'
    }
}

export const placeholderModelByType = (type: string|null) => {
    if(type == 'auto'){
        return 'Ex : F150'
    }else if(type == 'moto'){
        return 'Ex : PCX'
    }else if(type == 'bateau'){
        return 'Ex : 750'
    }else{
        return 'Modèle du véhicule'
    }
}

export const placeholderPlateByType = (type: string|null) => {
    if(type == 'auto'){
        return 'Ex : 256000P'
    }else if(type == 'moto'){
        return 'Ex : 3585YB'
    }else if(type == 'bateau'){
        return 'Ex : PY1453'
    }else{
        return 'Immatriculation du véhicule'
    }
}

export const iconUrlByType = (type: string|null) => {
    let icon = 'car.png'
    if(type == 'moto'){
        icon = 'scooter.png'
    }else if(type == 'bateau'){
        icon = 'boat.png'
    }
    
    return BASE_URL + 'assets/icons/' +icon
}