
import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../stores';
import * as DOMPurify from 'dompurify';

const MentionsLegales = observer(() => {

    const { apiStore } = useStores()

    const [legalPageContent, setLegalPageContent] = useState('')

    useEffect(() => {
        apiStore.post('legal/mentions-legales').then((resp: any) => {
            setLegalPageContent(resp.page_content)
        })
    }, [])

    return (<>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header no-after bg-white">
            <div className="container container-empty no-after">
            </div>
        </section>

        <section className='py-4 static-page-container'>
            <div className="container" style={{ minHeight: 300 }}>
                <h5 className="section-title__title">Mentions légales</h5>
                <br />
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(legalPageContent) }} />
            </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
    </>
    )
}
)

export default MentionsLegales