
import { Link } from 'react-router-dom'
import { Autoplay, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { Form } from 'react-bootstrap';
import { FACEBOOK_APP_ID } from '../utils/Constants';
import Cleave from "cleave.js/react";
import { useStores } from '../stores';
import Spinner from 'react-bootstrap/Spinner'
import { Observer } from 'mobx-react';
import { useNavigate } from "react-router-dom";

const Inscription = () => {

    const navigate = useNavigate();

    const { apiStore, userStore } = useStores()

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')

    const [phone, setPhone] = useState('')
    const [phoneError, setPhoneError] = useState(false)

    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')

    useEffect(() => {
        if(userStore.user){
            navigate('/mon-compte')
        }
    }, [userStore.user])

    const toastError = (message: string) => {
        toast.error(message, { duration: 2000 })
    }

    const submit = () => {
        if (password != password2) {
            toastError('Vos mots de passe ne correspondent pas')
            return
        }
        if (password.length < 8 || password2.length < 8) {
            toastError('Votre mot de passe doit faire 8 caractères minimum')
            return
        }
        let payload = {
            firstname,
            lastname,
            email,
            phone,
            password
        }
        apiStore.post('register', payload).then((user: any) => {
            toast.success('Votre compte a bien été créé !', { duration: 2000 })
            userStore.setUser(user)
        }).catch(err => {
            if(err.error == true){
                toastError('Inscription impossible, cette adresse email est déjà utilisée')  
            }else{
                toastError('Inscription impossible, une erreur inconnue est survenue')
            }
        })
    }

    const responseFacebook = (response: any) => {
        console.log(response);
    }

    const isInvalidPassword = () => {
        if (password.length > 3 && password2.length > 3 && password.length < 8 && password2.length < 8) {
            return true
        }
        if (password.length >= 8 && password2.length > 4 && password != password2) {
            return true
        }
        return false
    }

    const isDisabledSubmit = () => {
        return email.length < 4
            || password.length < 8
            || firstname.length < 2
            || lastname.length < 2
            || password.length < 2
            || password2.length < 2
            || phone.length < 2
    }

    return (
        <Observer>{() => (
            <>
                <div className="stricky-header stricked-menu main-menu">
                    <div className="sticky-header__content"></div>
                </div>
                <section className="page-header no-after bg-white">
                    <div className="container container-empty no-after">
                    </div>
                </section>

                <section className='py-4'>
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-8">
                                <h5 className="section-title__title">Inscription</h5>
                                <br />
                                <div className="row dct-fields">
                                    <div className='col-lg-6'>
                                        <Form.Group>
                                            <Form.Label className='dct-label  mt-3'>Email</Form.Label>
                                            <Form.Control onChange={e => setEmail(e.target.value)} type="email" placeholder="Votre adresse email" />
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group>
                                            <Form.Label className='dct-label mt-3'>N° de téléphone</Form.Label>
                                            {/* <Form.Control onChange={e => setPhone(e.target.value)} type="number" placeholder="89 XX XX XX" /> */}
                                            <Cleave
                                                placeholder="89 XX XX XX"
                                                options={{
                                                    blocks: [2, 2, 2, 2],
                                                    delimiter: " ",
                                                    numericOnly: true
                                                }}
                                                onChange={e => setPhone(e.target.rawValue)}
                                                className="form-control"
                                            />
                                            {phoneError && <span className='helper-text__error'>Numéro de téléphone incorrect</span>}
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group>
                                            <Form.Label className='dct-label  mt-3'>Prénom</Form.Label>
                                            <Form.Control onChange={e => setFirstname(e.target.value)} type="text" placeholder="Votre prénom" />
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group>
                                            <Form.Label className='dct-label mt-3'>Nom</Form.Label>
                                            <Form.Control onChange={e => setLastname(e.target.value)} type="text" placeholder="Votre nom" />
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group>
                                            <Form.Label className='dct-label mt-3'>Mot de passe</Form.Label>
                                            <Form.Control onChange={e => setPassword(e.target.value)} className={isInvalidPassword() ? 'is-invalid' : ''} type="password" placeholder="Votre mot de passe" />
                                            <span className='helper-text'>Minimum 8 caractères</span>
                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group>
                                            <Form.Label className='dct-label mt-3'>Confirmation Mot de passe</Form.Label>
                                            <Form.Control onChange={e => setPassword2(e.target.value)} className={isInvalidPassword() ? 'is-invalid' : ''} type="password" placeholder="Confirmez votre mot de passe" />
                                            {isInvalidPassword() && <span className='helper-text__error'>Les mots de passe doivent correspondre</span>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12">
                                        <button disabled={isDisabledSubmit() || apiStore.isLoading} onClick={submit} className="thm-btn w-100 mt-3">
                                            {!apiStore.isLoading && <span>Valider</span>}
                                            {apiStore.isLoading && <Spinner animation="border" variant="light" size="sm" />}
                                        </button>
                                    </div>
                                </div>
                                <p className='mb-0 mt-3 text-center'>Déjà un compte ?</p>
                                <p className='text-center mb-0'>
                                    <Link className='text-dct' to="/connexion"><b>Je me connecte !</b></Link>
                                </p>

                                {/* <hr />
                                <p className='text-center'>OU</p>
                                <FacebookLogin
                                    appId={FACEBOOK_APP_ID}
                                    callback={responseFacebook}
                                    render={renderProps => (
                                        <div className='d-flex w-100'>
                                            <button className='btn dct-btn btn-facebook text-white mx-auto' onClick={renderProps.onClick}>
                                                <i className='fab fa-facebook-square mx-2' />
                                                Inscription avec Facebook
                                            </button>
                                        </div>
                                    )}
                                /> */}
                            </div>

                            <div className="col-lg-4">
                                <div className="section-title mb-0 mt-4">
                                    <span className="section-title__tagline mb-0">VOTRE COMPTE DROPLESS</span>
                                </div>
                                <div className="about-two__summery mt-1">
                                    Avec votre complte Dropless, gérez en un clic vos rendez-vous et vos véhicules
                                </div>
                                <ul className="about-two__list list-unstyled">
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Rendez-vous en ligne
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Déplacement à domicile
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Sans eau
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </section>

                <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link >
            </>
        )}</Observer>
    )
}
export default Inscription