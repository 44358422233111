import BGPh1 from '../assets/images/backgrounds/page-header-bg-1-1.jpg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../stores'
import { Spinner } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { paymentMethodBySlug } from '../utils/settings'
import moment from 'moment'
import { Checkbox, Modal } from 'antd'

import * as DOMPurify from 'dompurify';

const BookingConfirmation = observer(() => {

    const { paymentToken } = useParams()
    const navigate = useNavigate()

    const { userStore, apiStore, carStore } = useStores()

    const [booking, setBooking] = useState<any>({})
    const [detailExpanded, setDetailExpanded] = useState(false)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(null)
    const [paymentMethodInstructions, setPaymentMethodInstructions] = useState<any>()

    const [payzenFormFields, setPayzenFormFields] = useState<any>()

    const [modalCancelOpen, setModalCancelOpen] = useState(false)

    const [bookingErrorPaymentMessage, setBookingErrorPaymentMessage] = useState(false)

    // Conditions
    const [modalAcceptConditionsOpen, setModalAcceptConditionsOpen] = useState(false)
    const [userAcceptedConditions1, setUserAcceptedConditions1] = useState(false)
    const [userAcceptedConditions2, setUserAcceptedConditions2] = useState(false)
    const [tmpSelectedMethod, setTmpSelectedMethod] = useState('')

    const [paymentMethodsExpanded, setPaymentMethodsExpanded] = useState(false)

    // Check if user has been redirected from OSB
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const status = urlParams.get('status')
        if (status === 'fail') {
            setBookingErrorPaymentMessage(true)
        } else if (status === 'success') {
            toast.success('Félicitations ! Votre réservation a bien été confirmée');
        }
    }, [])

    const bookingConfirmed = () => {
        return selectedPaymentMethod == 'cash' || selectedPaymentMethod == 'check' || selectedPaymentMethod == 'wire_transfer' || booking.paidAt
    }

    const invoiceDetails = () => {
        if (!booking.invoiceDetails?.length) {
            return []
        }
        if (detailExpanded) {
            let or: any = []
            or = Object.assign(or, booking.invoiceDetails)
            return or.reverse()
        } else {
            return booking.invoiceDetails?.filter((item: any) => item.type == 'total')
        }
    }

    useEffect(() => {
        apiStore.post('booking/' + paymentToken, {}).then((booking: any) => {
            setBooking(booking)
            _setPaymentMethodAfterModalConfirm(booking.paymentMethod)
        }).catch(err => {
            toast.error('Réservation introuvable')
            window.location.href = '/page-introuvable'
        })
    }, [])

    const _deletePaymentMethod = () => {
        setSelectedPaymentMethod(null)
        apiChangePaymentMethod(null)
    }

    const _payByCard = () => {
        let form: any = document.getElementById('payzen-form')
        form.submit()
    }

    const _fetchPaymentMethodInstructions = (method: any) => {
        apiStore.post('booking/payment-settings/' + method).then((resp: any) => {
            setPaymentMethodInstructions(resp.content)
        })
    }

    const apiChangePaymentMethod = (method: string | any) => {
        apiStore.post('booking/' + paymentToken + '/edit', { paymentMethod: method }).then((booking: any) => {
            setBooking(booking)
            // window.scrollTo(0, 0)
            if (method === 'card' && !booking.paidAt) {
                // Load payzen form
                apiStore.post('checkout/form-payment/' + paymentToken, {}).then(resp => {
                    setPayzenFormFields(resp)
                }).catch(err => {
                    toast.error('Paiement par carte non disponible pour le moment')
                })
            } else {
                setPayzenFormFields([])
            }
        })
    }

    const _setPaymentMethod = (method: any) => {
        setBookingErrorPaymentMessage(false)
        setTmpSelectedMethod(method)
        if (method == 'card' || method == 'wire_transfer') {
            setUserAcceptedConditions2(true)
        } else {
            setUserAcceptedConditions2(false)
        }
        setModalAcceptConditionsOpen(true)
        // if ((method == 'card' || method == 'wire_transfer') && !booking.userAcceptedConditions1) {
        //     setModalAcceptConditionsOpen(true)
        // } else if ((method == 'cash' || method == 'check') && (!booking.userAcceptedConditions1 || !booking.userAcceptedConditions2)) {
        //     setModalAcceptConditionsOpen(true)
        // } else {
        //     // _setPaymentMethodAfterModalConfirm(method)
        // }
    }

    const _setPaymentMethodAfterModalConfirm = (methodArg: any) => {
        setModalAcceptConditionsOpen(false)
        setSelectedPaymentMethod(methodArg)
        if (methodArg) {
            if (methodArg !== selectedPaymentMethod) {
                apiChangePaymentMethod(methodArg)
            }
            _fetchPaymentMethodInstructions(methodArg)
        } else {
            setPaymentMethodInstructions(null)
        }
    }

    const _cancelBooking = () => {
        apiStore.post('booking/' + paymentToken + '/cancel', {}).then((response: any) => {
            if (response.status) {
                toast.success('Votre réservation a bien été annulée')
            } else {
                toast.error('Impossible d\'annuler votre réservation. Veuillez nous contacter')
            }
            setBooking(response.booking)
            setModalCancelOpen(false)
        })
    }

    return (
        <>
            <div className="page-wrapper">

                <div className="stricky-header stricked-menu main-menu">
                    <div className="sticky-header__content"></div>
                </div>
                <section className="page-header">
                    <div className="page-header__bg" style={{ backgroundImage: `url(${BGPh1})` }}></div>

                    <div className="container">
                        <h2>{booking.statePayment !== 'canceled' ? 'Confirmation de votre réservation' : 'Votre réservation'}</h2>
                    </div>
                </section>

                <section className="online-booking">

                    <div className="container" id='container-step-6'>

                        {booking.statePayment == 'canceled' && <div className="alert alert-danger mb-5" role="alert">
                            <h4 className="alert-heading">Réservation annulée</h4>
                            <hr />
                            <p className="mb-0">Cette réservation a été annulée.</p>
                        </div>}

                        {bookingErrorPaymentMessage && <div className="alert alert-danger mb-5" role="alert">
                            <h4 className="alert-heading">Erreur de paiement</h4>
                            <hr />
                            <p className="mb-0">Votre paiement a été refusé. Pour confirmer votre réservation, veuillez réessayer ou sélectionner un autre moyen de paiement</p>
                        </div>}

                        <div className="online-booking__top">
                            <div className="online-booking__count invisible">6</div>
                            <div className="section-title">
                                <span className="section-title__tagline">Confirmation</span>
                                <h2 className="section-title__title">Réservation #DCT{booking.id}</h2>
                                <p className='mt-4 mb-0'>Votre réservation a bien été enregistrée sous la référence <b>#DCT{booking.id}</b>.</p>
                            </div>
                        </div>

                        <div className="online-booking__extra">
                            {invoiceDetails().map((item: any, index: number) => (
                                <div key={item.price} className="online-booking__extra-item">
                                    <h3 className={"online-booking__extra-title " + (item.type == 'detail' ? 'text-secondary fw-normal' : 'text-uppercase')}>
                                        {item.name}
                                    </h3>
                                    <ul className="list-unstyled online-booking__extra-list">
                                        {item.type == 'total' && <li>
                                            Total incl. TVA (13%)
                                        </li>}
                                        {item.type == 'total' && <li className='cursor-pointer' onClick={() => setDetailExpanded(!detailExpanded)}>
                                            <i className={detailExpanded ? "fas fa-angle-up" : "fas fa-angle-down"}></i>
                                            {detailExpanded ? 'Masquer les détails' : 'Afficher les détails'}
                                        </li>}
                                        <li>
                                            <button className={"thm-btn " + (item.type == 'total' ? 'total-btn' : 'filled')}>
                                                {item.price.toLocaleString()} FCFP
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            ))}
                        </div>

                        {bookingConfirmed() && <div className="alert alert-success mt-4" role="alert">
                            <h4 className="alert-heading mb-0">Félicitations ! Votre réservation est confirmée</h4>
                        </div>}

                        {/* PAYMENT */}
                        {booking.statePayment !== 'canceled' && <>
                            <hr className='secondary-hr my-5' />

                            {booking.paymentMethod !== null && <>
                                <div className='mt-5'>
                                    <div>
                                        <span className="section-title__tagline">Règlement</span>
                                        <h2 className="section-title__title">{paymentMethodBySlug(booking.paymentMethod)}</h2>
                                        <p className='mt-4 mb-3'>Instructions concernant le {paymentMethodBySlug(booking.paymentMethod)} :</p>

                                        {!booking.paidAt && paymentMethodInstructions && <>
                                            <div className="alert alert-warning" role="alert">
                                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(paymentMethodInstructions) }} />
                                            </div>
                                        </>}

                                        {payzenFormFields?.signature && <div className='d-none'>
                                            <form id='payzen-form' method="POST" action="https://secure.osb.pf/vads-payment/">
                                                {Object.entries(payzenFormFields).map((item: any) => (
                                                    <input key={item[0]} type="hidden" name={item[0]} value={item[1]} />
                                                ))}
                                                <input type="submit" name="payer" value="Payer" />
                                            </form>
                                        </div>}

                                        {booking.paidAt && <div className="alert alert-success" role="alert">
                                            Règlement effectué par carte bancaire le {moment(booking.paidAt).format('LLL')}
                                        </div>}
                                        {(booking.paidAt === null || booking.paidAt === '') && <div className='d-flex justify-content-end'>
                                            <button onClick={_deletePaymentMethod} disabled={apiStore.isLoading} className="thm-btn filled" style={{ cursor: 'pointer' }}>
                                                {!apiStore.isLoading && <span>Modifier le moyen de paiement</span>}
                                                {apiStore.isLoading && <Spinner animation="border" variant="dark" size="sm" />}
                                            </button>
                                            {booking.paymentMethod === 'card' && <button onClick={_payByCard} disabled={apiStore.isLoading || !payzenFormFields?.signature} className="thm-btn ms-3">
                                                {!apiStore.isLoading && <span>Régler ma réservation</span>}
                                                {apiStore.isLoading && <Spinner animation="border" variant="dark" size="sm" />}
                                            </button>}
                                        </div>}
                                    </div>
                                </div>
                            </>}

                            {booking.paymentMethod == null && <>
                                <div className='mt-5'>
                                    <div className="section-title">
                                        <span className="section-title__tagline">Choix du moyen de paiement</span>
                                        <h2 className="section-title__title">Paiement</h2>
                                        <p className='mt-4 mb-0'>Pour que votre réservation soit validée et prise en compte, merci de sélectionner un moyen de paiement :</p>
                                    </div>
                                </div>

                                <div className='row' style={{ marginTop: -20 }}>
                                    <div className="col-12 col-md-6">
                                        <div className="price-card">
                                            <p className="fw-bold text-dark mb-0">
                                                Carte bancaire
                                            </p>
                                            <p className='mt-2 mb-4'>
                                                Paiement sécurisé via Payzen OSB
                                            </p>
                                            <button disabled={apiStore.isLoading} onClick={() => _setPaymentMethod('card')} className={"thm-btn m-auto "}>
                                                {selectedPaymentMethod == 'card' ? 'Sélectionné' : 'Sélectionner'}
                                            </button >
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="price-card">
                                            <p className="fw-bold text-dark mb-0">
                                                Virement
                                            </p>
                                            <p className='mt-2 mb-4'>
                                                Virement local
                                            </p>
                                            <button disabled={apiStore.isLoading} onClick={() => _setPaymentMethod('wire_transfer')} className={"thm-btn m-auto "}>
                                                {selectedPaymentMethod == 'wire_transfer' ? 'Sélectionné' : 'Sélectionner'}
                                            </button >
                                        </div>
                                    </div>
                                </div>

                                {!paymentMethodsExpanded && <div>
                                    <br />
                                    <p className='cursor-pointer mb-0 text-center' onClick={() => setPaymentMethodsExpanded(true)}>
                                        <i className={"fas fa-angle-down text-info"}></i>&nbsp;
                                        Afficher plus de moyens de paiement
                                        &nbsp;<i className={"fas fa-angle-down text-info"}></i>
                                    </p>
                                </div>}

                                {paymentMethodsExpanded && <div className='row'>
                                    <div className="col-12 col-md-6">
                                        <div className="price-card">
                                            <p className="fw-bold text-dark mb-0">
                                                Espèces
                                            </p>
                                            <p className='mt-2 mb-4'>
                                                Préparation de la somme exacte
                                            </p>
                                            <button disabled={apiStore.isLoading} onClick={() => _setPaymentMethod('cash')} className={"thm-btn m-auto "}>
                                                {selectedPaymentMethod == 'cash' ? 'Sélectionné' : 'Sélectionner'}
                                            </button >
                                        </div>
                                    </div>
                                    {/* <div className="col-12 col-md-6">
                                        <div className="price-card">
                                            <p className="fw-bold text-dark mb-0">
                                                Chèque
                                            </p>
                                            <p className='mt-2 mb-4'>
                                                Préparation du chèque
                                            </p>
                                            <button disabled={apiStore.isLoading} onClick={() => _setPaymentMethod('check')} className={"thm-btn m-auto "}>
                                                {selectedPaymentMethod == 'check' ? 'Sélectionné' : 'Sélectionner'}
                                            </button >
                                        </div>
                                    </div> */}
                                </div>}

                            </>}
                        </>}

                        <hr className='secondary-hr my-5' />

                        <div className='mt-5'>
                            <div>
                                <h2 className="section-title__title">Détails de la réservation</h2>
                                <br />
                                <div className="online-booking__extra">
                                    <div className="online-booking__extra-item">
                                        <div className='plate-card'>
                                            Vos informations
                                        </div>
                                        <div>
                                            <h3 className="online-booking__extra-title mb-ncard fw-normal">
                                                {booking.intervention?.contactFirstName} {booking.intervention?.contactLastName}
                                            </h3>
                                        </div>
                                        <ul className="list-unstyled online-booking__extra-list">
                                            <li>
                                                <i className="fas fa-phone"></i>
                                                {booking.intervention?.contactPhone}
                                            </li>
                                            <li>
                                                <i className="fas fa-envelope"></i>
                                                {booking.intervention?.contactEmail}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="online-booking__extra mt-3">
                                    <div className="online-booking__extra-item">
                                        <div className='plate-card'>
                                            Votre véhicule
                                        </div>
                                        <div>
                                            <h3 className="online-booking__extra-title mb-ncard fw-normal">
                                                {booking.intervention?.vehicle.brand} {booking.intervention?.vehicle.model}
                                            </h3>
                                        </div>
                                        <ul className="list-unstyled online-booking__extra-list">
                                            <li>
                                                <i className="fas fa-receipt"></i>
                                                {booking.intervention?.vehicle.plate}
                                            </li>
                                            {/* <li>
                                                <i className="fas fa-car"></i>
                                                {carStore.categoryByKey(booking?.intervention?.vehicle?.category)?.name}
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>

                                <div className="online-booking__extra mt-3">
                                    <div className="online-booking__extra-item">
                                        <div className='plate-card'>
                                            Prestation sélectionnée
                                        </div>
                                        <div>
                                            <h3 className="online-booking__extra-title mb-ncard fw-normal">
                                                {booking.intervention?.price.prestation.name}
                                            </h3>
                                        </div>
                                        <ul className="list-unstyled online-booking__extra-list">
                                            <li>
                                                <i className="far fa-clock"></i>
                                                {booking.intervention?.price.time}
                                            </li>
                                            <li>
                                                <i className="fas fa-tag"></i>
                                                {booking.intervention?.price.price.toLocaleString()} FCFP
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="online-booking__extra mt-3">
                                    <div className="online-booking__extra-item">
                                        <div className='plate-card'>
                                            Date et lieu
                                        </div>
                                        <div>
                                            <h3 className="online-booking__extra-title mb-ncard fw-normal">
                                                {moment(booking.intervention?.date).format('LL')}
                                            </h3>
                                        </div>
                                        <ul className="list-unstyled online-booking__extra-list">
                                            <li>
                                                <i className="fas fa-map-marker"></i>
                                                {booking.intervention?.address.commune.name}, {booking.intervention?.address.fullAddress}
                                            </li>
                                            {booking.intervention?.virtualStart && <li>
                                                <i className="far fa-clock"></i>
                                                {moment(booking.intervention?.virtualStart).format('HH:mm')} - {moment(booking.intervention?.virtualEnd).format('HH:mm')}
                                            </li>}
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* CANCEL */}
                        {booking.statePayment !== 'canceled' && !booking.paidAt && <>
                            <hr className='secondary-hr my-5' />

                            <div className='mt-5'>
                                <div>
                                    <h2 className="section-title__title">Annulation</h2>
                                    <p className='mt-4 mb-3'>Vous pouvez annuler jusqu'à 48 heures avant le début de votre prestation</p>

                                    {(booking.paidAt === null || booking.paidAt === '') && <div className='d-flex justify-content-end'>
                                        <button onClick={() => setModalCancelOpen(true)} disabled={apiStore.isLoading} className="thm-btn thm-btn-danger ms-3">
                                            <span>Annuler ma réservation</span>
                                        </button>
                                    </div>}
                                </div>
                            </div>
                        </>}


                    </div>

                </section>

            </div>

            {/* ACCEPT CGU MODAL */}
            <Modal
                open={modalAcceptConditionsOpen}
                onOk={() => _setPaymentMethodAfterModalConfirm(tmpSelectedMethod)}
                onCancel={() => setModalAcceptConditionsOpen(false)}
                okText="Valider"
                cancelText="Fermer"
                okButtonProps={{
                    disabled: (!userAcceptedConditions1 || !userAcceptedConditions2)
                }}
            >
                <div className='d-block'>
                    <Checkbox onChange={e => setUserAcceptedConditions1(e.target.checked)}>&nbsp;J'accepte les <a target='_blank' href='/conditions-generales'>conditions générales d'utilisation de vente</a></Checkbox>
                </div>
                {(tmpSelectedMethod == 'cash' || tmpSelectedMethod == 'check') && <div className='d-block mt-4'>
                    <Checkbox onChange={e => setUserAcceptedConditions2(e.target.checked)}>&nbsp;Je m'engage à régler intégralement ma prestation au moment où celle-ci sera effectuée</Checkbox>
                </div>}
            </Modal>

            {/* CANCEL MODAL */}
            <Modal
                open={modalCancelOpen}
                onOk={_cancelBooking}
                onCancel={() => setModalCancelOpen(false)}
                okText="Confirmer l'annulation"
                cancelText="Fermer"
                maskStyle={{ borderRadius: 0 }}
                okButtonProps={{ className: 'btn-disconnect' }}
            >
                <h5 className="vehicle-modal-title text-danger">Annulation</h5>
                <p className='my-4 text-secondary fw-normal'>Voulez-vous vraiment annuler votre réservation ?</p>

            </Modal>

            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        </>
    )
})

export default BookingConfirmation