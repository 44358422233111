
import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { Form, Spinner } from 'react-bootstrap';
import { FACEBOOK_APP_ID } from '../utils/Constants';
import { useStores } from '../stores';
import { observer, Observer } from 'mobx-react';

import { Input, Modal } from 'antd'

const Connexion = observer(() => {

    const { apiStore, userStore } = useStores()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [forgotPassEmail, setForgotPassEmail] = useState('')
    const [forgotPassModalOpen, setForgotPassModalOpen] = useState(false)

    const navigate = useNavigate()

    const submit = () => {
        let payload = {
            username: email,
            password
        }
        apiStore.post('login_check', payload).then((user: any) => {
            toast.success('Ravi de vous revoir', { duration: 2000 })
            userStore.setUser(user)
            userStore.fetchUser()
        }).catch(err => {
            toast.error('Identifiants invalides. Merci de vérifier votre saisie')
        })
    }

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            submit()
        }
    }

    useEffect(() => {
        if (userStore.user) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const redirect = urlParams.get('redirect')
            if (redirect == 'booking') {
                navigate('/prendre-rendez-vous')
            } else {
                navigate('/mon-compte')
            }
        }
    }, [userStore.user])

    const toastError = (message: string) => {
        toast.error(message, { duration: 2000 })
    }

    const responseFacebook = (response: any) => {
        console.log(response);
        apiStore.post('login_check', { username: email, password: response.id }).then(resp => {
            userStore.setUser(resp)
            userStore.fetchUser()
        }).catch(err => {
            const [firstPart, secondPart] = response.name.split(' ');
            let payload = {
                firstname: firstPart,
                lastname: secondPart,
                email,
                phone: '',
                password: response.id
            }
            apiStore.post('register', payload).then((user: any) => {
                toast.success('Votre compte a bien été créé !', { duration: 2000 })
                userStore.setUser(user)
            }).catch(err => {
                if (err.error == true) {
                    toastError('Inscription impossible, cette adresse email est déjà utilisée')
                } else {
                    toastError('Inscription impossible, une erreur inconnue est survenue')
                }
            })
        })
    }

    const isDisabledSignInButton = () => {
        return (email.length < 4 && password.length < 4) || apiStore.isLoading
    }

    useEffect(() => {
        if (!forgotPassModalOpen) {
            setForgotPassEmail('')
        }
    }, [forgotPassModalOpen])

    const _handleOk = () => {
        apiStore.post('request-new-password', {
            email: forgotPassEmail
        }).then(resp => {
            toast.success('Un email de réinitialisation vient de vous être envoyé', { duration: 2000 })
            console.log(resp)
        }).catch(err => {
            toast.error('Une erreur est survenue. Veuillez réessayer plus tard', { duration: 2000 })
        }).finally(() => {
            setForgotPassModalOpen(false)
        })
    }

    const isDisabledSubmit = () => {
        return forgotPassEmail.length < 4
    }

    return (<>
        <div className="stricky-header stricked-menu main-menu">
            <div className="sticky-header__content"></div>
        </div>
        <section className="page-header no-after bg-white">
            <div className="container container-empty no-after">
            </div>
        </section>

        <section className='py-4'>
            <div className="container">
                <div className="row">

                    <div className="col-lg-8">
                        <h5 className="section-title__title">Connexion</h5>
                        <br />
                        <div className="row dct-fields">
                            <Form.Group>
                                <Form.Label className='dct-label'>Email</Form.Label>
                                <Form.Control onChange={e => setEmail(e.target.value)} type="email" placeholder="Votre adresse email" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className='dct-label'>Mot de passe</Form.Label>
                                <Form.Control onKeyUp={handleKeyPress} onChange={e => setPassword(e.target.value)} type="password" placeholder="Votre mot de passe" />
                            </Form.Group>
                            <div className='d-flex justify-content-end'>
                                <a onClick={() => setForgotPassModalOpen(true)} className='text-dct' style={{ cursor: 'pointer' }}>Mot de passe oublié ?</a>
                            </div>
                            <div className="col-md-12">
                                <button disabled={isDisabledSignInButton()} onClick={submit} className="thm-btn w-100 mt-4">
                                    {!apiStore.isLoading && <span>Valider</span>}
                                    {apiStore.isLoading && <Spinner animation="border" variant="light" size="sm" />}
                                </button>
                            </div>
                        </div>
                        <p className='mb-0 mt-3 text-center'>Pas encore de compte ?</p>
                        <p className='text-center mb-0'>
                            <Link className='text-dct' to="/inscription"><b>Je m'inscris !</b></Link>
                        </p>

                        <hr />
                        <p className='text-center'>OU</p>
                        <FacebookLogin
                            appId={FACEBOOK_APP_ID}
                            callback={responseFacebook}
                            scope='public_profile,email'
                            render={renderProps => (
                                <div className='d-flex w-100'>
                                    <button className='btn dct-btn btn-facebook text-white mx-auto' onClick={renderProps.onClick}>
                                        <i className='fab fa-facebook-square mx-2' />
                                        Continuer avec Facebook
                                    </button>
                                </div>
                            )}
                        />
                    </div>

                    <div className="col-lg-4">
                        <div className="section-title mb-0 mt-4">
                            <span className="section-title__tagline mb-0">VOTRE COMPTE DROPLESS</span>
                        </div>
                        <div className="about-two__summery mt-1">
                            Avec votre complte Dropless, gérez en un clic vos rendez-vous et vos véhicules
                        </div>
                        <ul className="about-two__list list-unstyled">
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Rendez-vous en ligne
                            </li>
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Déplacement à domicile
                            </li>
                            <li>
                                <i className="fa fa-check-circle"></i>
                                Sans eau
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </section>
        <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        <>
            <Modal
                open={forgotPassModalOpen}
                onOk={_handleOk}
                confirmLoading={apiStore.isLoading}
                onCancel={() => setForgotPassModalOpen(false)}
                okText={'Valider'}
                cancelText={'Annuler'}
                maskStyle={{ borderRadius: 0 }}
                okButtonProps={{ disabled: isDisabledSubmit() }}
                centered={true}
            >
                <h5 className="vehicle-modal-title">Mot de passe oublié</h5>
                <p className='my-4 text-secondary fw-normal'>Afin de réinitialiser votre mot de passe, veuillez saisir votre adresse mail. Si ce mail est relié à un compte, un mail vous sera envoyé pour définir un nouveau mot de passe.</p>

                <div className='row'>
                    <div className='col-lg-12'>
                        <p className='mb-1'>Votre adresse e-mail</p>
                        <Input value={forgotPassEmail} onChange={e => setForgotPassEmail(e.target.value)} placeholder={'Ex: compte@gmail.com'} />
                    </div>

                </div>

            </Modal>
        </>
    </>
    )
}
)

export default Connexion