import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useStores } from '../stores'

const Navbar = () => {

    const navigate = useNavigate()

    const location = useLocation()
    const path = location.pathname
    const [menu, setmenu] = useState<any>({})
    const [search, setsearch] = useState(false)
    const [cart, setcart] = useState(false)
    const [mobile, setmobile] = useState(false)
    const [homeDrop, sethomeDrop] = useState(false)
    const [headerDrop, setheaderDrop] = useState(false)
    const [servicesDrop, setservicesDrop] = useState(false)
    const [shopDrop, setshopDrop] = useState(false)
    const [newsDrop, setnewsDrop] = useState(false)
    const [pagesDrop, setpagesDrop] = useState(false)

    const [modalDisconnectOpen, setModalDisconnectOpen] = useState(false);

    const { userStore } = useStores()

    const activeMenu = () => {
        console.log(path)
        if (path === "/" || path === "/home2") {
            setmenu({ home: true })
        } else if (path === "/about") {
            setmenu({ about: true })
        } else if (path === "/services" || path === "/servicedetails") {
            setmenu({ service: true })
        } else if (path === "/onlinebooking" || path === "/pricnig" || path === "/gallery" || path === "/help" || path === "/error") {
            setmenu({ pages: true })
        } else if (path === "/shops" || path === "/shopdetails") {
            setmenu({ shop: true })
        } else if (path === "/newsmain" || path === "/newsdetails") {
            setmenu({ news: true })
        } else if (path === '/faqs') {
            setmenu({ faqs: true })
        } else if (path === '/mon-compte') {
            setmenu({ account: true })
        } else if (path === '/pricing') {
            setmenu({ pricing: true })
        } else if (path === '/pro') {
            setmenu({ pro: true })
        } else {
            setmenu({ login: true })
        }
    }

    const [sticky, setSticky] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
        activeMenu()
    }, [path]);

    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }

    const handleOk = () => {
        userStore.disconnect()
        toast('Vous avez bien été déconnecté. À bientôt !')
        setModalDisconnectOpen(false);
        navigate('/connexion')
    };

    return (
        <>
            <header className="main-header">
                <div className="topbar">
                    <div className="container-fluid p-0">
                        <div className="topbar__inner">
                            <ul className="list-unstyled topbar__infos">
                                <li className="topbar__infos-item">
                                    <Link to="#" className="topbar__infos-link"><i className="far fa-clock"></i> Lundi au Vendredi - 08:00 à
                                        16:00</Link>
                                </li>
                                <li className="topbar__infos-item">
                                    <Link to="tel:+68989502319" className="topbar__infos-link" ><i className="fa fa-phone-alt"></i>89 50 23 19</Link>
                                </li>
                                <li className="topbar__infos-item">
                                    <Link to="tel:+68989402320" className="topbar__infos-link" ><i className="fa fa-phone-alt"></i>89 40 23 20</Link>
                                </li>
                            </ul>
                            <div className="topbar__social">
                                <a target="_blank" href="https://www.facebook.com/droplesstahiti" className="fab fa-facebook-square"></a>
                                <a target="_blank" href="https://www.instagram.com/droplesscarwash/?hl=fr" className="fab fa-instagram"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className={`main-menu ${sticky && "stricky-header stricked-menu stricky-fixed"}`}>
                    <div className="container-fluid">
                        <div className="main-menu__inner">
                            <div className="main-menu__logo">
                                <Link to="/">
                                    <img src="/assets/images/logo-color.png" width="180" alt="Dropless carwash Tahiti" />
                                </Link>
                            </div>
                            <ul className="main-menu__list">
                                <li className={`dropdown ${menu.home && "current"}`}>
                                    <Link to="/">Accueil</Link>
                                </li>
                                <li className={`dropdown ${menu.pricing && "current"}`}>
                                    <Link to="/pricing">Tarifs</Link>
                                </li>
                                <li className={`dropdown ${menu.pro && "current"}`}>
                                    <Link to="/pro">Pro / Franchise</Link>
                                </li>
                                <li className={`dropdown ${menu.about && "current"}`}>
                                    <Link to="/about">À propos</Link>
                                </li>
                                <li className={`dropdown ${menu.faqs && "current"}`}>
                                    <Link to="/faqs">FAQ</Link>
                                </li>
                                <li className={`dropdown ${menu.account && "current"}`}>
                                    <Link to={userStore.user ? '/mon-compte' : '/connexion'}>{userStore.user ? 'Mon compte' : 'Inscription / connexion'}</Link>
                                </li>
                                {/* <li className={`dropdown ${menu.pages && "current"}`}>
                                    <Link to="#">Pages</Link>
                                    <ul>
                                        <li><Link to="/onlinebooking">Online Booking</Link></li>
                                        <li><Link to="/pricing">Pricing</Link></li>
                                        <li><Link to="/gallery">Gallery</Link></li>
                                        <li><Link to="/help">Help</Link></li>
                                        <li><Link to="/error">Error Page</Link></li>
                                    </ul>
                                </li> */}
                            </ul>
                            <div className="main-menu__right">
                                <Link to="#" onClick={() => setmobile(true)} className="mobile-nav__toggler"><i className="fa fa-bars"></i></Link>
                                <Link to="/prendre-rendez-vous" className="thm-btn">
                                    Prendre rendez-vous
                                    <i className="fas fa-car"></i>
                                </Link>
                                {userStore.user && <button onClick={() => setModalDisconnectOpen(true)} className="thm-btn thm-btn-danger ms-3">
                                    <i className="fas fa-power-off" style={{ marginLeft: 0 }}></i>
                                </button>}
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <div className={`mobile-nav__wrapper ${mobile ? "expanded" : ""}`}>
                <div className="mobile-nav__overlay mobile-nav__toggler" onClick={() => setmobile(false)}></div>
                <div className="mobile-nav__content">
                    <span className="mobile-nav__close mobile-nav__toggler" onClick={() => setmobile(false)}><i className="fa fa-times"></i></span>

                    <div className="logo-box">
                        <Link to="/" aria-label="logo image"><img src="assets/images/logo-light.png" width="155" alt="" /></Link>
                    </div>
                    <div className="mobile-nav__container">
                        <ul className="main-menu__list">
                            <li>
                                <Link to="/" onClick={() => setmobile(false)}>Accueil</Link>
                            </li>
                            <li>
                                <Link to="/pricing" onClick={() => setmobile(false)}>Tarifs</Link>
                            </li>
                            <li>
                                <Link to="/pro" onClick={() => setmobile(false)}>Pro / Franchise</Link>
                            </li>
                            <li>
                                <Link to="/about" onClick={() => setmobile(false)}>À propos</Link>
                            </li>
                            <li>
                                <Link to="/faqs" onClick={() => setmobile(false)}>FAQ</Link>
                            </li>
                            <li>
                                <Link to={userStore.user ? '/mon-compte' : '/connexion'} onClick={() => setmobile(false)}>{userStore.user ? 'Mon compte' : 'Inscription / connexion'}</Link>
                            </li>
                        </ul>
                    </div>
                                
                    <div>
                        <br/>
                        <Link to="/prendre-rendez-vous" className="thm-btn" onClick={() => setmobile(false)}>
                            Prendre rendez-vous
                            <i className="fas fa-car"></i>
                        </Link>
                        <br/><br/>
                    </div>

                    <ul className="mobile-nav__contact list-unstyled">
                        <li>
                            <i className="fa fa-envelope"></i>
                            <Link to="mailto:contact@droplesstahiti.com">contact@droplesstahiti.com</Link>
                        </li>
                        <li>
                            <i className="fa fa-phone-alt"></i>
                            <Link to="tel:+68989502319">89 50 23 19</Link>
                        </li>
                        <li>
                            <i className="fa fa-phone-alt"></i>
                            <Link to="tel:+68989402320">89 40 23 20</Link>
                        </li>
                    </ul>
                    <div className="mobile-nav__top">
                        <div className="mobile-nav__social">
                            <a target="_blank" href="https://www.facebook.com/droplesstahiti" className="fab fa-facebook-square"></a>
                            <a target="_blank" href="https://www.instagram.com/droplesscarwash/?hl=fr" className="fab fa-instagram"></a>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                open={modalDisconnectOpen}
                onOk={handleOk}
                onCancel={() => setModalDisconnectOpen(false)}
                okText="Je me déconnecte"
                cancelText="Annuler"
                maskStyle={{ borderRadius: 0 }}
                okButtonProps={{ className: 'btn-disconnect' }}
            >
                <h5 className="vehicle-modal-title text-danger">Déconnexion</h5>
                <p className='my-4 text-secondary fw-normal'>Voulez-vous mettre fin à votre session ?</p>

            </Modal>
        </>
    )
}
export default Navbar