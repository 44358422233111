import { apiService } from './../services/ApiService';
import {
    makeAutoObservable
} from 'mobx';
import currentUser from '../utils/currentUser';
import { isExpiredToken } from '../utils/tokenUtils';
import { RootStore } from '.';

export default class ApiStore {

    rootStore: any = null;

    isLoading: boolean = false;
    imageHash: string = '';

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.initImageHash()
        makeAutoObservable(this);
    }

    async initImageHash() {
        // Try to fetch hash from storage
        const hashValue = localStorage.getItem('@imageHash')
        if(hashValue == null){ // Create it if it does not exists
            this.updateImageHash()
        }else{
            this.imageHash = hashValue
        }
        console.log(this.imageHash)
    }

    async updateImageHash(){
        let hash = (new Date()).getTime().toString()
        this.imageHash = hash
        localStorage.setItem('@imageHash', hash)
        return hash
    }

    async refreshToken(refreshToken: string) {
        const { userStore } = this.rootStore
        let newTokens: any = await apiService.post('token/refresh', null, { refreshToken })
        userStore.setTokens(newTokens)
    }

    async post(action: string, params: any = null, disableLoading = false) {

        this.setLoading(!disableLoading);
        let user = currentUser(this.rootStore);

        // Before making api request, check that token is still valid. If not, we need to refresh it
        if (user && user.token && isExpiredToken(user.token)) {
            await this.refreshToken(user.refreshToken)
        }

        return new Promise(async (resolve, reject) => {
            apiService.post(action, user, params).then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            }).finally(() => {
                setTimeout(() => {
                    this.setLoading(false);
                }, 300)
            })
        });
    }

    setLoading(isLoading: any) {
        this.isLoading = isLoading;
    }
}
