import React, { useEffect, useState } from 'react'
import { useStores } from '../stores'
import { Card } from 'antd'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const Booking = ({
    booking = null,
}: {
    booking?: any,
}) => {


    const { apiStore, userStore, geoStore } = useStores()

    const navigate = useNavigate()


    return (
        <>
            <Card className='custom-ant-card' title={
                <div className='d-flex justify-content-between align-items-center'>
                    <p><b>{booking.intervention.price.prestation.name}</b> - {moment(booking.intervention.date).format('LL')} - #DCT-{booking.id}</p>
                    {booking.statePayment == 'waiting' && <span className="badge badge-danger">En attente de paiement</span>}
                    {booking.statePayment == 'canceled' && <span className="badge badge-danger">Annulée</span>}
                </div>
            } style={{ borderRadius: 0, marginBottom: 15 }}
                bordered={false}
            >
                <div className='row'>
                    <div className='col-md-8 col-12'>
                        <div className='row'>
                            <div className='col-md-6 col-12'>
                                <ul className="list-unstyled online-booking__extra-list d-block">
                                    <li>
                                        <i className="fas fa-car"></i>
                                        {booking.intervention.vehicle.brand} {booking.intervention.vehicle.model}
                                    </li>
                                    {booking.intervention.virtualStart && <li>
                                        <i className="far fa-clock"></i>
                                        {moment(booking.intervention.virtualStart).format('HH:mm')} - {moment(booking.intervention.virtualEnd).format('HH:mm')}
                                    </li>}
                                </ul>
                            </div>
                            <div className='col-md-6 col-12'>
                                <ul className="list-unstyled online-booking__extra-list d-block">
                                    <li>
                                        <i className="fas fa-map-marker"></i>
                                        {booking.intervention.address.commune.name}, {booking.intervention.address.fullAddress}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-12 d-flex justify-content-end'>
                        <button className="thm-btn" onClick={() => navigate('/reservation/' + booking.paymentToken)} style={{ margin: 'auto 0 auto auto' }}>
                            Détails
                            <i className="fa fa-arrow-alt-circle-right"></i>
                        </button>
                    </div>
                </div>
            </Card>
        </>
    )

}

export default Booking