import BGPh1 from '../../assets/images/backgrounds/page-header-bg-1-1.jpg'
import BGErr from '../../assets/images/shapes/404-bg.png'
import { Link } from 'react-router-dom'
import React from 'react'

const Error = () => {
    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPh1})` }}></div>

                <div className="container">
                    <h2>Erreur</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="index.html">Accueil</Link></li>
                        <li>/</li>
                        <li><span>Erreur</span></li>
                    </ul>
                </div>
            </section>

            <section className="error-section">
                <div className="container">

                    <h3 className="error-section__subtitle">Page introuvable</h3>
                    <p className="error-section__text my-5">La page que vous recherchez n'existe pas.</p>
                    <Link to="/" className="thm-btn">
                        Accueil
                        <i className="far fa-arrow-alt-circle-right"></i>
                    </Link>
                </div>
            </section>

            <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link>
        </>
    )
}

export default Error