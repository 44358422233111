export const API_URL = "api/"
// export const  BASE_URL = "http://192.168.3.78:8000/";
// export const BASE_URL = "http://localhost:8000/";
export const BASE_URL = "https://api.droplesstahiti.swbt.me/";
export const MAPS_TOKEN = "";

export const FACEBOOK_APP_ID = '1187451721880041'

export const siteAsset = (picture: string) => {
    return BASE_URL + 'site_assets/' + picture
}

export const paymentMethodBySlug = (slug: string) => {
    if (slug == 'card') {
        return 'Paiement par carte'
    } else if (slug == 'check') {
        return 'Paiement par chèque'
    } else if (slug == 'cash') {
        return 'Paiement en espèces'
    } else if (slug == 'wire_transfer') {
        return 'Paiement par virement bancaire'
    } else {
        return 'Paiement'
    }
}