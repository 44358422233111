import BGFBg1 from '../../assets/images/backgrounds/footer-bg-1.png'
import { Link } from 'react-router-dom'
import React from 'react'

export const Footer = () => {
    return (
        <>
            <footer className="main-footer">
                <div className="image-layer" style={{ backgroundImage: `url(${BGFBg1})` }}></div>

                <div className="upper-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="footer-widget footer-widget__about">
                                    <Link to="/" className="footer-widget__logo">
                                        <img src="assets/images/logo-color.png" width="180" alt="" />
                                    </Link>
                                    <p className="footer-widget__text">Revisitez la manière de laver votre voiture. DROPLESS CARWASH est un lave auto mobile et autonome.</p>
                                    <div className="footer-widget__social">
                                        <a target="_blank" href="https://www.facebook.com/droplesstahiti"><i className="fab fa-facebook-square"></i></a>
                                        <a target="_blank" href="https://www.instagram.com/droplesscarwash/?hl=fr"><i className="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="footer-widget footer-widget__links">
                                    <h3 className="footer-widget__title">Liens utiles</h3>
                                    <div className="footer-widget__links-wrap">
                                        <ul className="list-unstyled">
                                            <li><Link to="/about">
                                                À propos
                                            </Link></li>
                                            <li><Link to="/pricing">
                                                Tarifs
                                            </Link></li>
                                            <li><Link to="/faqs">
                                                FAQ
                                            </Link></li>
                                        </ul>
                                        <ul className="list-unstyled">
                                            <li><Link to="/prendre-rendez-vous">
                                                Prendre rendez-vous
                                            </Link></li>
                                            <li><a href='mailto:contact@droplesstahiti.com'>
                                                Nous contacter
                                            </a></li>
                                            <li><Link to="/mon-compte">
                                                Mon compte
                                            </Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="footer-widget footer-widget__contact">
                                    <h3 className="footer-widget__title">Contactez-nous</h3>
                                    <p className="footer-widget__text">Rue Paul Bernière - BP 52708 - 98716 Pirae</p>
                                    <ul className="footer-widget__contact-list list-unstyled">
                                        <li>
                                            <Link to="mailto:contact@droplesstahiti.com"><i className="fa fa-envelope"></i>contact@droplesstahiti.com</Link>
                                        </li>
                                        <li>
                                            <Link to="tel:+68989502319"><i className="fa fa-phone-square-alt"></i>89 50 23 19</Link>
                                        </li>
                                        <li>
                                            <Link to="tel:+68989402320"><i className="fa fa-phone-square-alt"></i>89 40 23 20</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className="footer-widget footer-widget__mailchimp">
                                <h3 className="footer-widget__title">Newsletter</h3>
                                <p className="footer-widget__text">Inscrivez-vous à notre newsletter pour ne rater aucune info !</p>
                                <form action="#" className="footer-widget__mailchimp-form">
                                    <input type="text" placeholder="Votre email" />
                                    <button type="submit" className="thm-btn">
                                        Valider
                                        <i className="far fa-arrow-alt-circle-right"></i>
                                    </button>
                                </form>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
                <div className="bottom-footer">
                    <div className="container">
                        <div className="bottom-footer__inner">
                            <p className="bottom-footer__text">© Copyright {(new Date()).getFullYear()} Dropless Carwash Tahiti</p>
                            <p className="bottom-footer__text">
                                <Link to="/mentions-legales">Mentions légales</Link>
                                <Link to="/conditions-generales">Conditions générales</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}


export default Footer 